define('w8ben-frontend/utils/question-model', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Object.extend({
    hasWarningMessage: _ember['default'].computed('errorMessageCondition', 'answer', function () {
      if (_ember['default'].typeOf(this.get('errorMessageCondition')) === 'boolean') {
        return this.get('errorMessageCondition') === this.get('answer');
      }
      if (_ember['default'].typeOf(this.get('errorMessageCondition')) === 'string') {
        return this.get('errorMessageCondition') === this.get('answer');
      }
      return false;
    })
  });
});