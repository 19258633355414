define('w8ben-frontend/routes/lang', ['exports', 'ember', 'w8ben-frontend/config/environment'], function (exports, _ember, _w8benFrontendConfigEnvironment) {

    var languageMap = {
        de_de: 'dede',
        de_at: 'deat',
        en_gb: 'engb',
        en_za: 'enza',
        nl_nl: 'nlnl',
        de_ch: 'dech',
        en_ch: 'ench',
        it_ch: 'itch',
        fr_ch: 'frch',
        fr_fr: 'frfr',
        fr_lu: 'frlu',
        en_sg: 'ensg',
        en_au: 'enau',
        pt_pt: 'ptpt',
        es_es: 'eses',
        sv_se: 'svse',
        it_it: 'itit',
        zh_tw: 'zhtw',
        zh_cn: 'zhcn',
        ja_jp: 'jajp',
        no_no: 'nono',
        bg_bg: 'bgbg',
        hr_hr: 'hrhr',
        cy_cy: 'cycy',
        cs_cz: 'cscz',
        ee_ee: 'eeee',
        fi_fi: 'fifi',
        el_gr: 'elgr',
        hu_hu: 'huhu',
        lv_lv: 'lvlv',
        lt_lt: 'ltlt',
        mt_mt: 'mtmt',
        sk_sk: 'sksk',
        si_si: 'sisi',
        is_is: 'isis',
        ar_ae: 'arae',
        en_ae: 'enae',
        ro_ro: 'roro',
        da_dk: 'dadk'
    };

    exports['default'] = _ember['default'].Route.extend({
        i18n: _ember['default'].inject.service(),
        reporting: _ember['default'].inject.service(),
        beforeModel: function beforeModel(transition) {
            var lang = transition.params.lang.lang.toLowerCase();
            var locale = undefined;
            if (languageMap[lang]) {
                locale = languageMap[lang];
            } else {
                locale = _w8benFrontendConfigEnvironment['default'].i18n.defaultLocale;
                this.get("reporting").error("Unrecognized language code: " + lang);
            }

            this.set('i18n.locale', locale);
        }
    });
});