define('w8ben-frontend/services/reporting', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    /**
     * Before we manually call Sentry with an error, we set some context around
     * the user so we can better understand what led to it
     */
    setContext: function setContext(context) {

      /*    Raven.setUserContext({
            id: this.get('session.data.authenticated.sessionData.clientID')
          });
      */
      Raven.setExtraContext(context);
    },

    /**
     * Send a warn message to Sentry
     *
     * @param  {String} message The message to appear in Sentry
     * @param  {Object} context Additional object with error context
     */
    warn: function warn(message) {
      var context = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      this.setContext(context);

      Raven.captureMessage(message, {
        level: 'warning'
      });
    },

    /**
     * Send a log message to Sentry
     *
     * @param  {String} message The message to appear in Sentry
     * @param  {Object} context Additional object with error context
     */
    log: function log(message) {
      var context = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      this.setContext(context);

      Raven.captureMessage(message, {
        level: 'info'
      });
    },

    /**
     * Send an error message to Sentry. This will trigger a PagerDuty alert.
     *
     * @param  {String} message The message to appear in Sentry
     * @param  {Object} context Additional object with error context
     */
    error: function error(message) {
      var context = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      this.setContext(context);

      Raven.captureMessage(message, {
        level: 'error'
      });
    }

  });
});
/* global Raven*/