define('w8ben-frontend/components/question-component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    i18n: _ember['default'].inject.service(),
    questions: _ember['default'].inject.service(),
    device: _ember['default'].inject.service(),
    classNames: ['question', 'clearfix'],
    classNameBindings: ['isOptional:is_optional', 'model.validationErrorsMessages:error', 'typeClass'],
    typeClass: _ember['default'].computed('model.type', function () {
      var className = ['question'];
      if (this.get('model.type')) {
        className.push(this.get('model.type'));
        return className.join('-');
      }
      return false;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.classNames.push(this.get('className'));
    },

    didDestroyElement: function didDestroyElement() {
      this.sendAction('removeElement', this.get('name'));
    },

    getDeviced: function getDeviced(mobile, desktop) {
      return this.get('device.isMobile') && mobile ? mobile : desktop;
    },

    getText: _ember['default'].computed(function () {
      return this.getDeviced(this.get('model.text_mobile'), this.get('model.text'));
    }),

    getTooltip: _ember['default'].computed(function () {
      return this.getDeviced(this.get('model.tooltip_mobile'), this.get('model.tooltip'));
    }),

    getErrorMessage: _ember['default'].computed(function () {
      return this.getDeviced(this.get('model.errorMessage_mobile'), this.get('model.errorMessage'));
    }),

    getErrorMessageNextLine: _ember['default'].computed(function () {
      return this.getDeviced(this.get('model.errorMessageNextLine_mobile'), this.get('model.errorMessageNextLine'));
    }),

    getDynamicErrorMessage: _ember['default'].computed(function () {
      return this.getDeviced(this.get('model.dynamicErrorMessage_mobile'), this.get('model.dynamicErrorMessage'));
    }),

    actions: {
      answer: function answer(val) {
        this.set('model.validationErrorsMessages', null);
        this.sendAction('answer', this.get('name'), val);
      }
    }
  });
});