define("w8ben-frontend/services/environment", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Service.extend({

    /**
     * A map of all environment-specific details
     *
     * @type {Object}
     */

    environmentDetails: {
      dev: {
        apiSource: "https://net-w8ben-api.ig.com",
        myIgUrl: "https://net-w8ben.ig.com"
      },
      test: {
        apiSource: "https://net-w8ben-api.ig.com",
        myIgUrl: "https://net-w8ben.ig.com"
      },
      uat: {
        apiSource: "https://web-w8ben-api.ig.com",
        myIgUrl: "https://web-w8ben.ig.com"
      },
      prod: {
        apiSource: "https://w8ben-api.ig.com",
        myIgUrl: "https://w8ben.ig.com"
      }
    },

    /**
     * Map that turns a host prefix into an environment name
     *
     * @type {Object}
     */
    prefixMap: {
      net: "test",
      web: "uat",
      demo: "demo"
    },

    /**
     * Set an environment object that can be referenced for all environment-dependant properties
     */
    init: function init() {
      if (!this.get('windowLocation')) {
        this.set('windowLocation', window.location);
      }

      var host = this.get('windowLocation').hostname;
      var environment = this.getEnvironmentName(host);

      this.set('details', {
        name: environment,
        apiSource: this.environmentDetails[environment].apiSource,
        myIgUrl: this.environmentDetails[environment].myIgUrl
      });
    },

    /**
     * Given the curren host name, return what environment we are currently in
     *
     * @param  {String} host The current hostname
     * @return {String}      The current environment
     */
    getEnvironmentName: function getEnvironmentName(host) {
      if (this.stringContains(host, ['192.168.1.1', 'localhost'])) {
        return "dev";
      }
      if (host === 'www.ig.com' || host === "w8ben.ig.com") {
        return "prod";
      }

      if (this.stringContains(host, 'web.ig.com')) {
        return "uat";
      }
      return this.prefixMap[host.split('-')[0]];
    },

    /**
     * Helper method to determine whether a given string contains another
     *
     * @param  {String} haystack String to search
     * @param  {String} needle   Substring to find
     * @return {Bool}            Whether the string is present
     */
    stringContains: function stringContains(haystack, needle) {
      function check(haystack, needle) {
        return haystack.indexOf(needle) >= 0;
      }

      if (needle instanceof Array) {
        return needle.any(function (n) {
          return check(haystack, n);
        });
      } else {
        return check(haystack, needle);
      }
    }
  });
});