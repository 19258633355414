define('w8ben-frontend/components/errors-list-component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    device: _ember['default'].inject.service(),
    getMessages: _ember['default'].computed('errors', function () {
      var _this = this;

      return this.get('errors').map(function (msg) {
        if (_ember['default'].typeOf(msg) === 'object') {
          return msg[_this.get('device.deviceName')];
        }
        return msg;
      });
    })
  });
});