define('w8ben-frontend/controllers/lang/part2', ['exports', 'ember', 'w8ben-frontend/mixins/page', 'w8ben-frontend/data/countries', 'w8ben-frontend/data/tax_treaty_countries'], function (exports, _ember, _w8benFrontendMixinsPage, _w8benFrontendDataCountries, _w8benFrontendDataTax_treaty_countries) {
  exports['default'] = _ember['default'].Controller.extend(_w8benFrontendMixinsPage['default'], {
    i18n: _ember['default'].inject.service(),
    requiredQuestions: ['name_confirm', 'citizenship', 'address_confirm', 'country', 'mailing_address_same', 'username_confirm', 'residency', 'have_ssn_confirm'],
    countries: _w8benFrontendDataCountries['default'],
    taxTreatyCountries: _w8benFrontendDataTax_treaty_countries['default'],
    formattedAddress: _ember['default'].computed('userData.address', function () {
      var addressObject = this.get('userData.address');
      var line1 = addressObject.line1 || "";
      var line2 = addressObject.line2 ? ", " + addressObject.line2 : "";
      var town = addressObject.town || "";
      var postCode = addressObject.postCode ? ", " + addressObject.postCode : "";
      var country = addressObject.country ? ", " + addressObject.country : "";
      return '' + line1 + line2 + '<br/>' + town + postCode + country;
    }),
    formattedName: _ember['default'].computed('userData.name', 'userData.firstname', 'userData.surname', 'userData.title', function () {
      var surname = this.get('userData.surname') || "";
      var firstname = this.get('userData.firstname') || "";
      var title = this.get('userData.title') || "";
      if (this.get('i18n.locale') === 'jajp') {
        return surname + " " + firstname;
      }
      return title + " " + firstname + " " + surname;
    }),
    showDobConfirmation: _ember['default'].computed.bool('userData.dateOfBirth'),

    showCountryMismatchError: _ember['default'].computed('userData.address', 'questionsData.country.answer', function () {
      var countryInFile = this.get('userData.address.countryCode');
      var countryInDropdown = this.get('questionsData.country.answer');
      if (!countryInDropdown) {
        return false;
      }
      return countryInFile !== countryInDropdown;
    }),

    allAnswersReady: _ember['default'].computed('allAnswersFilled', 'showCountryMismatchError', function () {
      return this.get('allAnswersFilled') && !this.get('showCountryMismatchError');
    }),

    showDobInsert: _ember['default'].computed('userData.dateOfBirth', 'questionsData.dob_confirm.answer', function () {
      var hasDob = this.get('userData.dateOfBirth');
      var dobConfirmed = this.get('questionsData.dob_confirm.answer');
      var showDobInsert = !hasDob || dobConfirmed === false;
      if (showDobInsert) {
        this.get('requiredQuestions').pushObject('dob_insert');
        this.get('requiredQuestions').removeObject('dob_confirm');
      } else {
        this.get('requiredQuestions').removeObject('dob_insert');
        this.get('requiredQuestions').pushObject('dob_confirm');
      }
      return showDobInsert;
    }),
    actions: {
      onSubmit: function onSubmit() {
        if (this.get("allAnswersReady")) {
          this.transitionToRoute('lang.certification');
        }
      }
    }
  });
});