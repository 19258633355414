define("w8ben-frontend/locales/zhcn/translations", ["exports"], function (exports) {
  exports["default"] = {
    "accept_esign_label": "接受并附上电子签名",
    "accept_esign_validation_error": "",
    "username_confirm_text": "这是您的用户名称吗？",
    "username_confirm_validation_error": "请修改用户名称，确保信息准确，以便完成表格。",
    "address_confirm_text": "这是您的永久地址吗？",
    "address_confirm_tooltip": "如果您为了在某个国家/地区缴纳所得税而声称自己是该国家/地区的居民，则您在这个国家/地区的地址是您的永久居住地址。如果您正在填写 W-8BEN 表，要求根据所得税条约降低预扣税率，您必须以条约所要求的方式确定自己的居住地。请不要使用金融机构的地址、邮政信箱或仅用于邮寄目的的地址。如果您在任何国家/地区都没有税务居住地，则您的经常居住地应视为永久居住地。",
    "address_confirm_validation_error": "",
    "buttons_back": "返回",
    "buttons_next": "下一步",
    "buttons_no": "否",
    "buttons_yes": "是",
    "citizenship_error_message": "",
    "citizenship_text": "您的国籍是？",
    "citizenship_tooltip": "请输入您的国籍。如果您有双重国籍，请输入您在填写该表格时兼具公民和居民身份的国家。如果您不在自己具有公民身份的任何国家居住，请输入您最近居住过的国家。我们目前不向美国公民或美国税务居民提供股票交易服务。",
    "citizenship_validation_error": "",
    "citizenship_us_selected": "我们认为 W-9 表是最适合您的。请参阅 <a href='https://www.ig.com/zh/us-stocks' target='_blank'> 添加美股</a> 选择适当的表格。",
    "conduct_income_error_message": "对于收入与在美国从事的贸易或业务有实际关联的人士，我们目前不提供美股交易服务。",
    "conduct_income_text": "您的收入是否与在美国从事的贸易或业务有实际关联？",
    "conduct_income_tooltip": "对于此类收入，个人必须在其年度美国所得税申报表或纳税数据表上申报。 一般来说，外国人在美国从事贸易或业务的时候，凡是来源于美国的收入（固定或可厘定的年度或定期收入除外，如利息、股息、租金及某些类似款项）都被视为与美国的贸易或业务有实际关联的收入。 如果您对自己的身份有任何疑问，请参阅美国国税局（IRS）指引或咨询您的税务顾问。",
    "country_text": "国家/地区",
    "country_validation_error": "",
    "dob_confirm_validation_error": "",
    "dob_insert_text": "出生日期",
    "dob_insert_validation_error": "",
    "dob_text": "这是您的出生日期吗？",
    "dob_format": "DD MM YYYY",
    "former_renounce_citizenship_error_message": "我们我们认为 W-9 表是最适合您的。请参阅 <a href='https://www.ig.com/zh/us-stocks' target='_blank'> 添加美股</a> 选择适当的表格。",
    "former_renounce_citizenship_text": "您是否已正式放弃美国公民身份？",
    "have_ssn_confirm_input_placeholder": "请输入",
    "have_ssn_confirm_text": "您是否有美国税务号码（SSN 或 ITIN）？",
    "have_ssn_confirm_tooltip": "请输入您所在税务辖区颁发给您的税务号码（TIN）。如果您没有获发税务号码，或者您所在管辖区不颁发税务号码，则不必输入。在这种情况下，您可以输入出生日期。",
    "have_ssn_confirm_validation_error": "",
    "individual_acting_error_message": "",
    "individual_acting_text": "您是以本人的个人身份填写表格吗？",
    "intermediary_acting_error_message": "按规定，我们不能提供税务意见，但根据您的答案，我们认为 W-8BEN 表最适合您的情况。如果您不确定，请参阅 <a href='http://www.irs.gov/' target='_blank'> 美国国税局（IRS）指引</a>或咨询您的税务顾问。",
    "intermediary_acting_text": "您是中介吗？",
    "intermediary_acting_tooltip": "该表格仅供收入之个人实益拥有人使用。一般来说，如果您作为代名人、保管人、代理人或者您是代表某些类型的信托及合伙关系收取收入，则您不是实益拥有人（故属于中介）。如果您对自己的身份有任何疑问，请参阅美国国税局（IRS）指引或咨询您的税务顾问。",
    "mailing_address_confirm_text": "这是您的邮件地址吗？",
    "mailing_address_confirm_validation_error": "",
    "mailing_address_same_text": "您的邮件地址是否与上述地址相同？",
    "mailing_address_same_validation_error": "",
    "name_confirm_text": "您的名字是",
    "name_confirm_validation_error": "",
    "page_part2_confirmation": "上述居民身份系基于美国与该国家/地区之间的所得税条约判定。有关美国税务条约的清单，请参阅 <a href='http://www.irs.gov/' target='_blank'> 美国国税局（IRS）网站</a>。",
    "page_confirmation_content": "<p>您现在可以用差价合约账户收取美国股息等值收入。 <p/><p> 该表格的有效期至 {{form_valid_until}}。我们将要求您在 {{update_year}}验证和更新该信息。<p/><p>请注意：变更您的姓名、地址、税务居住地或其他个人资料，可能会使该表格无效，并可能需要重新提交表格。如果您的数据或个人情况有任何变化并想要通知我们，或者您有任何其他疑问，请前往 MyIG/个人设置，或致电我们的服务部。</p>",
    "page_confirmation_title": "您现在可以交易美股了",
    "page_irs_guidance": "按规定，我们不能提供税务意见，但根据您的答案，我们认为您适合使用 W-8IMY 表。请参阅 <a href='https://www.ig.com/zh/us-stocks' target='_blank'> 添加美股</a> 選擇適當的表格。",
    "page_page1_lid": "为确保您收到美股的正确股息付款及股息调整，我们需要请您确认一些信息。<br/> 首先，请回答最多 6 个问题，以选出最合适的表格。",
    "page_page2_lid": "我们需要您确认以下数据。<br/> 如果您需要进行任何变更，请前往<a href='https://www.ig.com/zh/us-stocks' target='_blank'> 添加美股</a>。",
    "page_page2_lid_mobile": "我们需要您确认以下信息。如何您需要进行更改，请通过网页版平台登录，并进入 My IG 的个人信息页面。",
    "page_part1_title": "第一部分 — 确认实益拥有人",
    "page_part2_title": "第二部分 — 申报税务条约优惠（仅适用于第三章）",
    "page_part3_title": "第三部分 — 认证",
    "page_subtitle": "W8-BEN",
    "page_title": "美股交易",
    "page_to_personal_details": "请先前往 <a href='https://www.ig.com/uk/myig/settings/personal-details' target='_parent'>个人资料</a>更正此信息，才可以填妥该表格。",
    "page_to_personal_details_mobile": "在填写此表格前，请先通过网页版平台登录，在 My IG 的个人信息页面更改该信息。",
    "present_taxation_subject_error_message": "我们我们认为 W-9 表是最适合您的。请参阅 <a href='https://www.ig.com/zh/us-stocks' target='_blank'> 添加美股</a> 选择适当的表格。",
    "present_taxation_subject_text": "您目前是否以美国公民或外国居民身份在美国缴税？",
    "question_elements_day_short": "DD",
    "question_elements_month_short": "MM",
    "question_elements_year_short": "YYYY",
    "residency_text": "兹证明实益拥有人是以下国家/地区的居民",
    "residency_validation_error": "这与您先前提供的信息不符，请更新您的 <a href='https://www.ig.com/uk/myig/settings/personal-details' target='_parent'> 个人信息 </a>。",
    "residency_validation_error_mobile": "该信息与您之前提供的不符。请通过网页版平台登录，并进入 My IG 的个人信息页面进行更新。",
    "submit_certification": "提交",
    "submit_index": "在线填写 W-8BEN 表",
    "tin_number_text": "选填：外国税务号码",
    "us_born_text": "您出生在美国吗？",
    "form_for_individuals_only": "该代用 W-8BEN 表仅供个人使用。对于您的情况，请按照适用于非个人客户的指示操作。",
    "add_us_stocks_text": "请参阅 <a href='https://www.ig.com/uk/investments/share-dealing/important-documents' target='_blank'> 添加美股</a> 选择适当的表格。",
    "select_text": "请选择",
    "question_elements_optional": "这是选填字段。",
    "non_treaty_country": "无条约国",
    "unanswered_error": "请确认信息是否正确",
    "certification_text": "\n根据有关伪证处罚条例，兹声明，本人已检查该表格上的信息，据本人所知所信，所有信息皆真实可信、完整无误。兹根据有关伪证处罚条例进一步证明：\n<ul>\n<li>本人确系该表格所涉及的所有收入的个人实益拥有人（或个人实益拥有人之授权签字人），或者作为外国金融机构之拥有人或账户持有人以个人身份使用该表格，</li>\n<li>该表格第 1 行所列人士{{title}} {{name}} {{surname}}不是美国人，</li>\n<li>该表格所涉及的收入：\n<ul>\n<li>与在美国从事的贸易或业务并无实际关联，</li>\n<li>与在美国从事的贸易或业务有实际关联，但毋须按照适用的所得税条约缴税，或</li>\n<li>系合伙人于有实际关联的合伙收入中所占的份额，</li>\n</ul>\n</li>\n<li>该表格第 1 行所列人士{{title}} {{name}} {{surname}}系第 9 行所列缔约国家/地区{{ residency }}（如有）的居民，上述居民身份系基于美国与该国家/地区之间的所得税条约判定，及</li>\n<li>对于券商交易或易货交易，实益拥有人系指示中所定义的免税收外国人。\n此外，本人授权将该表格提供给任何可控制、收取或保管本实益拥有人收入的预扣税代理，或任何可支出或支付本实益拥有人收入的预扣税代理。如果该表格所出具的任何证明变得不正确，本人同意将在 30 日内重新提交表格。\n本人进一步同意以电子方式提交该代用 W-8BEN 表，而且该电子签名将被视为接受已提供或已确认的相关信息，并构成具法律约束力的签名。\n除了为确定您的非美国个人身份并（如适用）降低您的预扣税率所必需的认证之外，美国国税局不要求您同意本文件的任何其他规定。</li>\n</ul>",

    "afghanistan": "Afghanistan",
    "albania": "Albania",
    "algeria": "Algeria",
    "american samoa": "American Samoa",
    "andorra": "Andorra",
    "angola": "Angola",
    "anguilla": "Anguilla",
    "antarctica": "Antarctica",
    "antigua and barbuda": "Antigua and Barbuda",
    "argentina": "Argentina",
    "armenia": "Armenia",
    "aruba": "Aruba",
    "australia": "Australia",
    "austria": "Austria",
    "azerbaijan": "Azerbaijan",
    "bahamas": "Bahamas",
    "bahrain": "Bahrain",
    "bangladesh": "Bangladesh",
    "barbados": "Barbados",
    "belarus": "Belarus",
    "belgium": "Belgium",
    "belize": "Belize",
    "benin": "Benin",
    "bermuda": "Bermuda",
    "bhutan": "Bhutan",
    "bolivia": "Bolivia",
    "bosnia and herzegowina": "Bosnia and Herzegowina",
    "botswana": "Botswana",
    "bouvet island": "Bouvet Island",
    "brazil": "Brazil",
    "british indian ocean territory": "British Indian Ocean Territory",
    "brunei darussalam": "Brunei Darussalam",
    "bulgaria": "Bulgaria",
    "burkina faso": "Burkina Faso",
    "burundi": "Burundi",
    "cambodia": "Bambodia",
    "cameroon": "Cameroon",
    "canada": "Canada",
    "cape verde": "Cape Verde",
    "cayman islands": "Cayman Islands",
    "central african republic": "Central African Republic",
    "chad": "Chad",
    "chile": "Chile",
    "china": "China",
    "christmas island": "Christmas Island",
    "cocos (keeling) islands": "Cocos (Keeling) Islands",
    "colombia": "Colombia",
    "comoros": "Comoros",
    "congo, democratic republic of (was zaire)": "Congo, Democratic Republic of (was Zaire)",
    "congo, peoples republic of": "Congo, Peoples Republic of",
    "cook islands": "Cook Islands",
    "costa rica": "Costa Rica",
    "cote d'ivoire": "Cote d'Ivoire",
    "croatia (local name: hrvatska)": "Croatia (local name: Hrvatska)",
    "cuba": "Cuba",
    "cyprus": "Cyprus",
    "czech republic": "Czech Republic",
    "denmark": "Denmark",
    "djibouti": "Djibouti",
    "dominica": "Dominica",
    "dominican republic": "Dominican Republic",
    "east timor": "East Timor",
    "ecuador": "Ecuador",
    "egypt": "Egypt",
    "el salvador": "El Salvador",
    "equatorial guinea": "Equatorial Guinea",
    "eritrea": "Eritrea",
    "estonia": "Estonia",
    "ethiopia": "Ethiopia",
    "falkland islands (malvinas)": "Falkland Islands (Malvinas)",
    "faroe islands": "Faroe Islands",
    "fiji": "Fiji",
    "finland": "Finland",
    "france": "France",
    "france, metropolitan": "France, Metropolitan",
    "french guiana": "French Guiana",
    "french polynesia": "French Polynesia",
    "french southern territories": "French Southern Territories",
    "gabon": "Gabon",
    "gambia": "Gambia",
    "georgia": "Georgia",
    "germany": "Germany",
    "ghana": "Ghana",
    "gibraltar": "Gibraltar",
    "greece": "Greece",
    "greenland": "Greenland",
    "grenada": "Grenada",
    "guadeloupe": "Guadeloupe",
    "guam": "Guam",
    "guatemala": "Guatemala",
    "guernsey islands": "Guernsey Islands",
    "guinea": "Guinea",
    "guinea-bissau": "Guinea-Bissau",
    "guyana": "Guyana",
    "haiti": "Haiti",
    "heard and mc donald islands": "Heard and Mc Donald Islands",
    "honduras": "Honduras",
    "hong kong": "Hong Kong",
    "hungary": "Hungary",
    "iceland": "Iceland",
    "india": "India",
    "indonesia": "Indonesia",
    "iran (islamic republic of)": "Iran (Islamic republic of)",
    "iraq": "Iraq",
    "ireland": "Ireland",
    "israel": "Israel",
    "italy": "Italy",
    "jamaica": "Jamaica",
    "japan": "Japan",
    "jersey island": "Jersey Island",
    "jordan": "Jordan",
    "kazakhstan": "Kazakhstan",
    "kenya": "Kenya",
    "kiribati": "Kiribati",
    "korea, democratic peoples republic of": "Korea, Democratic Peoples Republic of",
    "korea, republic of": "Korea, Republic of",
    "kosovo": "Kosovo",
    "kuwait": "Kuwait",
    "kyrgyzstan": "Kyrgyzstan",
    "lao peoples democratic republic": "Lao Peoples Democratic Republic",
    "latvia": "Latvia",
    "lebanon": "Lebanon",
    "lesotho": "Lesotho",
    "liberia": "Liberia",
    "libyan arab jamahiriya": "Libyan Arab Jamahiriya",
    "liechtenstein": "Liechtenstein",
    "lithuania": "Lithuania",
    "luxembourg": "Luxembourg",
    "macau": "Macau",
    "macedonia, the former yugoslav republic of": "Macedonia, the former Yugoslav Republic of",
    "madagascar": "Madagascar",
    "malawi": "Malawi",
    "malaysia": "Malaysia",
    "maldives": "Maldives",
    "mali": "Mali",
    "malta": "Malta",
    "marshall islands": "Marshall Islands",
    "martinique": "Martinique",
    "mauritania": "Mauritania",
    "mauritius": "Mauritius",
    "mayotte": "Mayotte",
    "mexico": "Mexico",
    "micronesia, federated states of": "Micronesia, Federated States of",
    "moldova, republic of": "Moldova, republic of",
    "monaco": "Monaco",
    "mongolia": "Mongolia",
    "montenegro": "Montenegro",
    "montserrat": "Montserrat",
    "morocco": "Morocco",
    "mozambique": "Mozambique",
    "myanmar": "Myanmar",
    "namibia": "Namibia",
    "nauru": "Nauru",
    "nepal": "Nepal",
    "netherlands": "Netherlands",
    "netherlands antilles": "Netherlands Antilles",
    "new caledonia": "New Caledonia",
    "new zealand": "New Zealand",
    "nicaragua": "Nicaragua",
    "niger": "Niger",
    "nigeria": "Nigeria",
    "niue": "Niue",
    "norfolk island": "Norfolk Island",
    "northern mariana islands": "Northern Mariana Islands",
    "norway": "Norway",
    "oman": "Oman",
    "pakistan": "Pakistan",
    "palau": "Palau",
    "palestinian territory, occupied": "Palestinian Territory, Occupied",
    "panama": "Panama",
    "papua new guinea": "Papua New Guinea",
    "paraguay": "Paraguay",
    "peru": "Peru",
    "philippines": "Philippines",
    "pitcairn": "Pitcairn",
    "poland": "Poland",
    "portugal": "Portugal",
    "puerto rico": "Puerto Rico",
    "qatar": "Qatar",
    "reunion": "Reunion",
    "romania": "Romania",
    "russian federation": "Russian Federation",
    "rwanda": "Rwanda",
    "saint kitts and nevis": "Saint Kitts and Nevis",
    "saint lucia": "Saint Lucia",
    "saint vincent and the grenadines": "Saint Vincent and The Grenadines",
    "samoa": "Samoa",
    "san marino": "San Marino",
    "sao tome and principe": "Sao Tome and Principe",
    "saudi arabia": "Saudi Arabia",
    "senegal": "Senegal",
    "serbia": "Serbia",
    "seychelles": "Seychelles",
    "sierra leone": "Sierra Leone",
    "singapore": "Singapore",
    "slovakia (slovak republic)": "Slovakia (Slovak Republic)",
    "slovenia": "Slovenia",
    "solomon islands": "Solomon Islands",
    "somalia": "Somalia",
    "south africa": "South Africa",
    "south georgia and the south sandwich islands": "South Georgia and The South Sandwich Islands",
    "spain": "Spain",
    "sri lanka": "Sri Lanka",
    "st. helena": "St. Helena",
    "st. pierre and miquelon": "St. Pierre and Miquelon",
    "sudan": "Sudan",
    "suriname": "Suriname",
    "svalbard and jan mayen islands": "Svalbard and Jan Mayen Islands",
    "swaziland": "Swaziland",
    "sweden": "Sweden",
    "switzerland": "Switzerland",
    "syrian arab republic": "Syrian Arab Republic",
    "taiwan": "Taiwan",
    "tajikistan": "Tajikistan",
    "tanzania, united republic of": "Tanzania, United Republic of",
    "thailand": "Thailand",
    "the isle of man": "The Isle of Man",
    "togo": "Togo",
    "tokelau": "Tokelau",
    "tonga": "Tonga",
    "trinidad and tobago": "Trinidad and Tobago",
    "tunisia": "Tunisia",
    "turkey": "Turkey",
    "turkmenistan": "Turkmenistan",
    "turks and caicos islands": "Turks and Caicos Islands",
    "tuvalu": "Tuvalu",
    "uganda": "Uganda",
    "ukraine": "Ukraine",
    "united arab emirates": "United Arab Emirates",
    "united kingdom": "United Kingdom",
    "united states": "United States",
    "united states minor outlying islands": "United States Minor Outlying Islands",
    "uruguay": "Uruguay",
    "uzbekistan": "Uzbekistan",
    "vanuatu": "Vanuatu",
    "vatican city state (holy see)": "Vatican City State (Holy See)",
    "venezuela": "Venezuela",
    "vietnam": "Vietnam",
    "virgin islands (british)": "Virgin Islands (British)",
    "virgin islands (u.s.)": "Virgin Islands (U.S.)",
    "wallis and futuna islands": "Wallis and Futuna Islands",
    "western sahara": "Western Sahara",
    "yemen": "Yemen",
    "yugoslavia": "Yugoslavia",
    "zambia": "Zambia",
    "zimbabwe": "Zimbabwe",
    "select_no_results": "no results found",
    "residency_tooltip": "如您非列表中国家的纳税居民，请在下拉菜单中选择“非协议国家”。"
  };
});