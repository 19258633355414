define("w8ben-frontend/templates/lang/certification", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 2
            },
            "end": {
              "line": 19,
              "column": 60
            }
          },
          "moduleName": "w8ben-frontend/templates/lang/certification.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["buttons_back"], [], ["loc", [null, [19, 40], [19, 60]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 22,
            "column": 0
          }
        },
        "moduleName": "w8ben-frontend/templates/lang/certification.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h1");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h2");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h4");
        dom.setAttribute(el1, "class", "section_title");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("form");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("hr");
        dom.setAttribute(el2, "class", "clearfix");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    \n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "type", "submit");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "submit-error");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [8]);
        var element1 = dom.childAt(element0, [5]);
        var morphs = new Array(10);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [4]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(fragment, [6]), 1, 1);
        morphs[4] = dom.createMorphAt(element0, 1, 1);
        morphs[5] = dom.createAttrMorph(element1, 'class');
        morphs[6] = dom.createElementMorph(element1);
        morphs[7] = dom.createMorphAt(element1, 0, 0);
        morphs[8] = dom.createMorphAt(element0, 7, 7);
        morphs[9] = dom.createMorphAt(dom.childAt(element0, [9]), 0, 0);
        return morphs;
      },
      statements: [["inline", "t", ["page_title"], [], ["loc", [null, [1, 4], [1, 22]]], 0, 0], ["inline", "t", ["page_subtitle"], [], ["loc", [null, [2, 4], [2, 26]]], 0, 0], ["inline", "t", ["page_part3_title"], [], ["loc", [null, [4, 26], [4, 51]]], 0, 0], ["inline", "t", ["certification_text"], ["title", ["subexpr", "@mut", [["get", "userData.title", ["loc", [null, [7, 33], [7, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "name", ["subexpr", "@mut", [["get", "userData.firstname", ["loc", [null, [7, 53], [7, 71]]], 0, 0, 0, 0]], [], [], 0, 0], "surname", ["subexpr", "@mut", [["get", "userData.surname", ["loc", [null, [7, 80], [7, 96]]], 0, 0, 0, 0]], [], [], 0, 0], "residency", ["subexpr", "t", [["get", "countryName", ["loc", [null, [7, 110], [7, 121]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 107], [7, 122]]], 0, 0]], ["loc", [null, [7, 2], [7, 125]]], 0, 0], ["inline", "question-component", [], ["name", "accept_esign", "classNames", "checkbox_field", "model", ["subexpr", "@mut", [["get", "questionsData.accept_esign", ["loc", [null, [13, 11], [13, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "answer", ["subexpr", "action", ["answer"], [], ["loc", [null, [14, 11], [14, 28]]], 0, 0]], ["loc", [null, [10, 2], [14, 31]]], 0, 0], ["attribute", "class", ["concat", ["submit_form ", ["subexpr", "unless", [["get", "allAnswersFilled", ["loc", [null, [18, 54], [18, 70]]], 0, 0, 0, 0], "inactive"], [], ["loc", [null, [18, 45], [18, 83]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["submit"], [], ["loc", [null, [18, 85], [18, 106]]], 0, 0], ["inline", "t", ["submit_certification"], [], ["loc", [null, [18, 107], [18, 135]]], 0, 0], ["block", "link-to", ["lang.part2"], ["class", "back"], 0, null, ["loc", [null, [19, 2], [19, 72]]]], ["content", "errorMessage", ["loc", [null, [20, 28], [20, 44]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});