define('w8ben-frontend/components/yesno-input-component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['yesno_input'],
    classNameBindings: ['model.validationErrorsMessages:error_input'],
    showInput: null,

    didInsertElement: function didInsertElement() {
      this.set('showInput', this.get('model.answer'));
    },

    actions: {
      checked: function checked(value) {
        if (value) {
          this.send('answer', null);
          this.set('showInput', true);
        } else {
          this.send('answer', value);
          this.set('showInput', false);
        }
      },
      answer: function answer(val) {
        this.sendAction('answer', val);
      }
    }
  });
});