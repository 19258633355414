define("w8ben-frontend/templates/components/checkbox-component", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 0
          }
        },
        "moduleName": "w8ben-frontend/templates/components/checkbox-component.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("label");
        dom.setAttribute(el1, "class", "checkbox_field");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "checkbox");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["inline", "input", [], ["type", "checkbox", "checked", ["subexpr", "eq", [["get", "model.answer", ["loc", [null, [2, 39], [2, 51]]], 0, 0, 0, 0], true], [], ["loc", [null, [2, 35], [2, 57]]], 0, 0], "change", ["subexpr", "action", ["toggleCheckbox"], [], ["loc", [null, [2, 65], [2, 90]]], 0, 0]], ["loc", [null, [2, 2], [2, 93]]], 0, 0], ["inline", "t", [["get", "model.checkBoxLabel", ["loc", [null, [4, 10], [4, 29]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 6], [4, 32]]], 0, 0], ["inline", "errors-list-component", [], ["errors", ["subexpr", "@mut", [["get", "model.validationErrorsMessages", ["loc", [null, [8, 31], [8, 61]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [8, 0], [8, 64]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});