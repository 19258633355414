define('w8ben-frontend/components/date-component', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Component.extend({
    reporting: _ember['default'].inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      var dateOfBirth = this.get('date');
      var re = /^(\w+) (\w+) (\d\d) (\d\d:\d\d:\d\d) (\w+) (\d\d\d\d)$/;
      dateOfBirth = dateOfBirth.replace(re, function ($0, day, month, date, time, zone, year) {
        return day + ' ' + month + ' ' + date + ' ' + year + ' ' + time + ' ' + 'UTC';
      });

      if (_moment['default'].utc(new Date(dateOfBirth)).toDate() === "Invalid Date") {
        this.get("reporting").error("Cannot parse " + dateOfBirth);
      }

      var date = _moment['default'].utc(new Date(dateOfBirth)).toObject();
      this.set('day', this.to2Digits(date.date));
      this.set('month', this.to2Digits(date.months + 1));
      this.set('year', date.years);
    },

    to2Digits: function to2Digits(number) {
      var output = number.toString();
      if (output.length === 1) {
        return '0' + output;
      }
      return output;
    },

    actions: {
      checked: function checked(val) {
        this.sendAction('answer', val);
      }
    }
  });
});