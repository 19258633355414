define('w8ben-frontend/services/validator', ['exports', 'ember', 'w8ben-frontend/validators/custom'], function (exports, _ember, _w8benFrontendValidatorsCustom) {
  exports['default'] = _ember['default'].Service.extend({
    questions: _ember['default'].inject.service(),
    isValid: false,
    validateQuestion: function validateQuestion(question) {
      var _this = this;

      if (question.validators) {
        return question.validators.reduce(function (errors, validator) {
          var validatorNames = Object.keys(validator);
          if (validatorNames.length > 0) {
            var err = validatorNames.reduce(function (errorNames, validatorName) {
              if (!_w8benFrontendValidatorsCustom['default'][validatorName](question, _this.get('questions.items'))) {
                errorNames.push(validator[validatorName]);
              }
              return errorNames;
            }, []);

            if (err.length > 0) {
              errors.push(err[0]);
            }
            return errors;
          }
        }, []);
      }
    },

    validate: function validate(requiredQuestions) {
      var _this2 = this;

      if (!requiredQuestions) {
        return true;
      }
      var hasErrors = requiredQuestions.reduce(function (hasError, Question) {
        var questionErrors = [];

        questionErrors = questionErrors.concat(_this2.validateQuestion(Question));

        if (!questionErrors.length) {
          Question.set('validationErrorsMessages', null);
          if (!hasError) {
            return false;
          }
        } else {
          Question.set('validationErrorsMessages', questionErrors);
        }
        return true;
      }, false);

      this.set('isValid', !hasErrors);
    }
  });
});