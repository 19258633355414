define('w8ben-frontend/instance-initializers/enable-revision-previews-s3-index', ['exports', 'ember-cli-deploy-ig-external/instance-initializers/enable-revision-previews-s3-index'], function (exports, _emberCliDeployIgExternalInstanceInitializersEnableRevisionPreviewsS3Index) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberCliDeployIgExternalInstanceInitializersEnableRevisionPreviewsS3Index['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberCliDeployIgExternalInstanceInitializersEnableRevisionPreviewsS3Index.initialize;
    }
  });
  Object.defineProperty(exports, 'windowLocation', {
    enumerable: true,
    get: function get() {
      return _emberCliDeployIgExternalInstanceInitializersEnableRevisionPreviewsS3Index.windowLocation;
    }
  });
});