define('w8ben-frontend/controllers/lang/certification', ['exports', 'ember', 'w8ben-frontend/data/countryCodes', 'w8ben-frontend/mixins/page'], function (exports, _ember, _w8benFrontendDataCountryCodes, _w8benFrontendMixinsPage) {
  exports['default'] = _ember['default'].Controller.extend(_w8benFrontendMixinsPage['default'], {
    reporting: _ember['default'].inject.service(),
    requiredQuestions: ['accept_esign'],
    countryCodes: _w8benFrontendDataCountryCodes['default'],
    countryName: _ember['default'].computed('questions.savedAnswers.country', function () {
      var countryCode = this.get('questions.savedAnswers.country');
      for (var country in _w8benFrontendDataCountryCodes['default']) {
        if (_w8benFrontendDataCountryCodes['default'][country] === countryCode) {
          return country;
        }
      }
      this.get("reporting").error("could not find country name for code: " + countryCode);
    }),
    actions: {
      onSubmit: function onSubmit() {
        var _this = this;

        if (this.get("allAnswersFilled")) {
          this.get('questions').save().then(function () {
            _this.transitionToRoute('lang.confirmation');
          }, function (reason) {
            _this.get("reporting").error("save form call failed with a reason: " + reason);
            _this.set("errorMessage", "There is a technical problem on our server and we are not able to process your request. We are most likely already working on fixing this.");
          });
        }
      }
    }
  });
});