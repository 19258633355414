define('w8ben-frontend/services/questions', ['exports', 'ember', 'w8ben-frontend/data/questions', 'w8ben-frontend/utils/question-model', 'w8ben-frontend/utils/custom'], function (exports, _ember, _w8benFrontendDataQuestions, _w8benFrontendUtilsQuestionModel, _w8benFrontendUtilsCustom) {
  exports['default'] = _ember['default'].Service.extend({
    environment: _ember['default'].inject.service(),
    validator: _ember['default'].inject.service(),
    user: _ember['default'].inject.service(),
    savedAnswers: {},
    init: function init() {
      var EmberObjectQuestions = Object.keys(_w8benFrontendDataQuestions['default']).reduce(function (newList, question) {
        newList[question] = _w8benFrontendUtilsQuestionModel['default'].create(_w8benFrontendDataQuestions['default'][question]);
        return newList;
      }, {});
      this.set('items', _ember['default'].Object.create(EmberObjectQuestions));
    },

    getQuestionById: function getQuestionById(id) {
      return this.get('items.' + id);
    },

    getQuestionsById: function getQuestionsById(requiredQuestionsArray) {
      var _this = this;

      return requiredQuestionsArray.map(function (questionId) {
        return _this.getQuestionById(questionId);
      });
    },

    saveAnswers: function saveAnswers() {
      this.set('savedAnswers', (0, _w8benFrontendUtilsCustom.extractAnswers)(this.get('items'), this.get('savedAnswers')));
    },

    loadSavedAnswers: function loadSavedAnswers() {
      var answers = this.get('savedAnswers');
      if (answers) {
        (0, _w8benFrontendUtilsCustom.bulkUpdateAnswers)(this.get('items'), answers);
      }
    },

    getUrl: function getUrl() {
      return this.get('environment.details.apiSource') + '/submitw8benformdetails';
    },

    prepareAnswers: function prepareAnswers() {
      var serialized = (0, _w8benFrontendUtilsCustom.serialize)(this.get('items')),
          items_json = Object.keys(serialized).reduce(function (json, key) {
        json[key] = serialized[key].get('answer');
        return json;
      }, {});
      var userData = this.get('user').data;
      items_json.user_data = userData;
      if (items_json.dob_insert === null || items_json.dob_insert === undefined) {
        items_json.dob_insert = "";
      }
      return JSON.stringify(items_json);
    },
    save: function save() {
      var _this2 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        return _ember['default'].$.ajax({
          type: "POST",
          contentType: 'application/json',
          url: _this2.getUrl(),
          data: _this2.prepareAnswers()
        }).then(function (data) {
          resolve(data);
        }, function (reason, status) {
          reject(reason.responseText || status);
        });
      });
    }
  });
});