define("w8ben-frontend/locales/zhtw/translations", ["exports"], function (exports) {
  exports["default"] = {
    "accept_esign_label": "接受並附上電子簽名",
    "accept_esign_validation_error": "",
    "username_confirm_text": "這是您的用戶名稱嗎？",
    "username_confirm_validation_error": "請修改用戶名稱，確保信息準確，以便完成表格。",
    "address_confirm_text": "這是您的永久地址嗎？",
    "address_confirm_tooltip": "如果您為了在某個國家/地區繳納所得稅而聲稱自己是該國家/地區的居民，則您在這個國家/地區的地址是您的永久居住地址。如果您正在填寫 W-8BEN 表，要求根據所得稅條約降低預扣稅率，您必須以條約所要求的方式確定自己的居住地。請不要使用金融機構的地址、郵政信箱或僅用於郵寄目的的地址。如果您在任何國家/地區都沒有稅務居住地，則您的經常居住地應視為永久居住地。",
    "address_confirm_validation_error": "",
    "buttons_back": "返回",
    "buttons_next": "下一步",
    "buttons_no": "否",
    "buttons_yes": "是",
    "citizenship_error_message": "",
    "citizenship_text": "您的國籍是？",
    "citizenship_tooltip": "請輸入您的國籍。如果您有雙重國籍，請輸入您在填寫該表格時兼具公民和居民身份的國家。如果您不在自己具有公民身份的任何國家居住，請輸入您最近居住過的國家。我們目前不向美國公民或美國稅務居民提供股票交易服務。",
    "citizenship_validation_error": "",
    "citizenship_us_selected": "我們認為 W-9 表是最適合您的。請參閱 <a href='https://www.ig.com/cn/us-stocks' target='_blank'> 添加美股</a> 選擇適當的表格。",
    "conduct_income_error_message": "對於收入與在美國從事的貿易或業務有實際關聯的人士，我們目前不提供美股交易服務。",
    "conduct_income_text": "您的收入是否與在美國從事的貿易或業務有實際關聯？",
    "conduct_income_tooltip": "對於此類收入，個人必須在其年度美國所得稅申報表或納稅資料表上申報。 一般來說，外國人在美國從事貿易或業務的時候，凡是來源於美國的收入（固定或可釐定的年度或定期收入除外，如利息、股息、租金及某些類似款項）都被視為與美國的貿易或業務有實際關聯的收入。 如果您對自己的身份有任何疑問，請參閱美國國稅局（IRS）指引或諮詢您的稅務顧問。",
    "country_text": "國家/地區",
    "country_validation_error": "",
    "dob_confirm_validation_error": "",
    "dob_insert_text": "出生日期",
    "dob_insert_validation_error": "",
    "dob_text": "這是您的出生日期嗎？",
    "dob_format": "DD MM YYYY",
    "former_renounce_citizenship_error_message": "我們我們認為 W-9 表是最適合您的。請參閱 <a href='https://www.ig.com/cn/us-stocks' target='_blank'> 添加美股</a> 選擇適當的表格。",
    "former_renounce_citizenship_text": "您是否已正式放棄美國公民身份？",
    "have_ssn_confirm_input_placeholder": "請輸入",
    "have_ssn_confirm_text": "您是否有美國稅務號碼（SSN 或 ITIN）？",
    "have_ssn_confirm_tooltip": "請輸入您所在稅務轄區頒發給您的稅務號碼（TIN）。如果您沒有獲發稅務號碼，或者您所在管轄區不頒發稅務號碼，則不必輸入。在這種情況下，您可以輸入出生日期。",
    "have_ssn_confirm_validation_error": "",
    "individual_acting_error_message": "",
    "individual_acting_text": "您是以本人的個人身份填寫表格嗎？",
    "intermediary_acting_error_message": "按規定，我們不能提供稅務意見，但根據您的答案，我們認為 W-8BEN 表最適合您的情況。如果您不確定，請參閱 <a href='http://www.irs.gov/' target='_blank'> 美國國稅局（IRS）指引</a>或諮詢您的稅務顧問。",
    "intermediary_acting_text": "您是中介嗎？",
    "intermediary_acting_tooltip": "該表格僅供收入之個人實益擁有人使用。一般來說，如果您作為代名人、保管人、代理人或者您是代表某些類型的信託及合夥關係收取收入，則您不是實益擁有人（故屬於中介）。如果您對自己的身份有任何疑問，請參閱美國國稅局（IRS）指引或諮詢您的稅務顧問。",
    "mailing_address_confirm_text": "這是您的郵寄地址嗎？",
    "mailing_address_confirm_validation_error": "",
    "mailing_address_same_text": "您的郵寄地址是否與上述地址相同？",
    "mailing_address_same_validation_error": "",
    "name_confirm_text": "您的名字是",
    "name_confirm_validation_error": "",
    "page_part2_confirmation": "上述居民身份係基於美國與該國家/地區之間的所得稅條約判定。有關美國稅務條約的清單，請參閱 <a href='http://www.irs.gov/' target='_blank'> 美國國稅局（IRS）網站</a>。",
    "page_confirmation_content": "<p>您現在可以用差價合約帳戶收取美國股息等值收入。 <p/><p> 該表格的有效期至 {{form_valid_until}}. 我們將要求您在 {{update_year}}驗證和更新該資訊。<p/><p>請注意：變更您的姓名、地址、稅務居住地或其他個人資料，可能會使該表格無效，並可能需要重新提交表格。如果您的資料或個人情況有任何變化並想要通知我們，或者您有任何其他疑問，請前往 MyIG/個人設置，或致電我們的服務部。</p>",
    "page_confirmation_title": "您現在可以交易美股了",
    "page_irs_guidance": "按規定，我們不能提供稅務意見，但根據您的答案，我們認為您適合使用 W-8IMY 表。請參閱 <a href='https://www.ig.com/cn/us-stocks' target='_blank'> 添加美股</a> 選擇適當的表格。",
    "page_page1_lid": "為確保您收到美股的正確股息付款及股息調整，我們需要請您確認一些資訊。<br/> 首先，請回答最多 6 個問題，以選出最合適的表格。",
    "page_page2_lid": "我們需要您確認以下資料。<br/> 如果您需要進行任何變更，請前往<a href='https://www.ig.com/cn/us-stocks' target='_blank'> 添加美股</a>。",
    "page_page2_lid_mobile": "我們需要您確認以下信息。如何您需要進行更改，請通過網頁版平台登錄，並進入 My IG 的個人信息頁面。",
    "page_part1_title": "第一部分 — 確認實益擁有人",
    "page_part2_title": "第二部分 — 申報稅務條約優惠（僅適用於第三章）",
    "page_part3_title": "第三部分 — 認證",
    "page_subtitle": "W8-BEN",
    "page_title": "美股交易",
    "page_to_personal_details": "請先前往 <a href='https://www.ig.com/uk/myig/settings/personal-details' target='_parent'>個人資料</a>更正此資訊，才可以填妥該表格。",
    "page_to_personal_details_mobile": "在填寫此表格前，請先通過網頁版平台登錄，在 My IG 的個人信息頁面更改該信息。",
    "present_taxation_subject_error_message": "我們我們認為 W-9 表是最適合您的。請參閱 <a href='https://www.ig.com/cn/us-stocks' target='_blank'> 添加美股</a> 選擇適當的表格。",
    "present_taxation_subject_text": "您目前是否以美國公民或外國居民身份在美國繳稅？",
    "question_elements_day_short": "DD",
    "question_elements_month_short": "MM",
    "question_elements_year_short": "YYYY",
    "residency_text": "茲證明實益擁有人是以下國家/地區的居民",
    "residency_validation_error": "這與您先前提供的信息不符，請更新您的 <a href='https://www.ig.com/uk/myig/settings/personal-details' target='_parent'> 個人信息 </a>。",
    "residency_validation_error_mobile": "該信息與您之前提供的不符。請通過網頁版平台登錄，並進入 My IG 的個人信息頁面進行更新。",
    "submit_certification": "提交",
    "submit_index": "線上填寫 W-8BEN 表",
    "tin_number_text": "選填：外國稅務號碼",
    "us_born_text": "您出生在美國嗎？",
    "form_for_individuals_only": "該代用 W-8BEN 表僅供個人使用。對於您的情況，請按照適用於非個人客戶的指示操作。",
    "add_us_stocks_text": "請參閱 <a href='https://www.ig.com/cn/us-stocks' target='_blank'> 添加美股</a> 選擇適當的表格。",
    "select_text": "請選擇",
    "question_elements_optional": "這是選填欄位。",
    "non_treaty_country": "無條約國",
    "unanswered_error": "請確認資訊是否正確",
    "certification_text": "\n根據有關偽證處罰條例，茲聲明，本人已檢查該表格上的資訊，據本人所知所信，所有資訊皆真實可信、完整無誤。茲根據有關偽證處罰條例進一步證明：\n<ul>\n<li>本人確係該表格所涉及的所有收入的個人實益擁有人（或個人實益擁有人之授權簽字人），或者作為外國金融機構之擁有人或帳戶持有人以個人身份使用該表格，</li>\n<li>該表格第 1 行所列人士{{title}} {{name}} {{surname}}不是美國人，</li>\n<li>該表格所涉及的收入：\n<ul>\n<li>與在美國從事的貿易或業務並無實際關聯，</li>\n<li>與在美國從事的貿易或業務有實際關聯，但毋須按照適用的所得稅條約繳稅，或</li>\n<li>係合夥人於有實際關聯的合夥收入中所佔的份額，</li>\n</ul>\n</li>\n<li>該表格第 1 行所列人士{{title}} {{name}} {{surname}}係第 9 行所列締約國家/地區{{ residency }}（如有）的居民，上述居民身份係基於美國與該國家/地區之間的所得稅條約判定，及</li>\n<li>對於券商交易或易貨交易，實益擁有人係指示中所定義的免稅收外國人。\n此外，本人授權將該表格提供給任何可控制、收取或保管本實益擁有人收入的預扣稅代理，或任何可支出或支付本實益擁有人收入的預扣稅代理。如果該表格所出具的任何證明變得不正確，本人同意將在 30 日內重新提交表格。\n本人進一步同意以電子方式提交該代用 W-8BEN 表，而且該電子簽名將被視為接受已提供或已確認的相關資訊，並構成具法律約束力的簽名。\n除了為確定您的非美國個人身份並（如適用）降低您的預扣稅率所必需的認證之外，美國國稅局不要求您同意本文件的任何其他規定。</li>\n</ul>",

    "afghanistan": "Afghanistan",
    "albania": "Albania",
    "algeria": "Algeria",
    "american samoa": "American Samoa",
    "andorra": "Andorra",
    "angola": "Angola",
    "anguilla": "Anguilla",
    "antarctica": "Antarctica",
    "antigua and barbuda": "Antigua and Barbuda",
    "argentina": "Argentina",
    "armenia": "Armenia",
    "aruba": "Aruba",
    "australia": "Australia",
    "austria": "Austria",
    "azerbaijan": "Azerbaijan",
    "bahamas": "Bahamas",
    "bahrain": "Bahrain",
    "bangladesh": "Bangladesh",
    "barbados": "Barbados",
    "belarus": "Belarus",
    "belgium": "Belgium",
    "belize": "Belize",
    "benin": "Benin",
    "bermuda": "Bermuda",
    "bhutan": "Bhutan",
    "bolivia": "Bolivia",
    "bosnia and herzegowina": "Bosnia and Herzegowina",
    "botswana": "Botswana",
    "bouvet island": "Bouvet Island",
    "brazil": "Brazil",
    "british indian ocean territory": "British Indian Ocean Territory",
    "brunei darussalam": "Brunei Darussalam",
    "bulgaria": "Bulgaria",
    "burkina faso": "Burkina Faso",
    "burundi": "Burundi",
    "cambodia": "Bambodia",
    "cameroon": "Cameroon",
    "canada": "Canada",
    "cape verde": "Cape Verde",
    "cayman islands": "Cayman Islands",
    "central african republic": "Central African Republic",
    "chad": "Chad",
    "chile": "Chile",
    "china": "China",
    "christmas island": "Christmas Island",
    "cocos (keeling) islands": "Cocos (Keeling) Islands",
    "colombia": "Colombia",
    "comoros": "Comoros",
    "congo, democratic republic of (was zaire)": "Congo, Democratic Republic of (was Zaire)",
    "congo, peoples republic of": "Congo, Peoples Republic of",
    "cook islands": "Cook Islands",
    "costa rica": "Costa Rica",
    "cote d'ivoire": "Cote d'Ivoire",
    "croatia (local name: hrvatska)": "Croatia (local name: Hrvatska)",
    "cuba": "Cuba",
    "cyprus": "Cyprus",
    "czech republic": "Czech Republic",
    "denmark": "Denmark",
    "djibouti": "Djibouti",
    "dominica": "Dominica",
    "dominican republic": "Dominican Republic",
    "east timor": "East Timor",
    "ecuador": "Ecuador",
    "egypt": "Egypt",
    "el salvador": "El Salvador",
    "equatorial guinea": "Equatorial Guinea",
    "eritrea": "Eritrea",
    "estonia": "Estonia",
    "ethiopia": "Ethiopia",
    "falkland islands (malvinas)": "Falkland Islands (Malvinas)",
    "faroe islands": "Faroe Islands",
    "fiji": "Fiji",
    "finland": "Finland",
    "france": "France",
    "france, metropolitan": "France, Metropolitan",
    "french guiana": "French Guiana",
    "french polynesia": "French Polynesia",
    "french southern territories": "French Southern Territories",
    "gabon": "Gabon",
    "gambia": "Gambia",
    "georgia": "Georgia",
    "germany": "Germany",
    "ghana": "Ghana",
    "gibraltar": "Gibraltar",
    "greece": "Greece",
    "greenland": "Greenland",
    "grenada": "Grenada",
    "guadeloupe": "Guadeloupe",
    "guam": "Guam",
    "guatemala": "Guatemala",
    "guernsey islands": "Guernsey Islands",
    "guinea": "Guinea",
    "guinea-bissau": "Guinea-Bissau",
    "guyana": "Guyana",
    "haiti": "Haiti",
    "heard and mc donald islands": "Heard and Mc Donald Islands",
    "honduras": "Honduras",
    "hong kong": "Hong Kong",
    "hungary": "Hungary",
    "iceland": "Iceland",
    "india": "India",
    "indonesia": "Indonesia",
    "iran (islamic republic of)": "Iran (Islamic republic of)",
    "iraq": "Iraq",
    "ireland": "Ireland",
    "israel": "Israel",
    "italy": "Italy",
    "jamaica": "Jamaica",
    "japan": "Japan",
    "jersey island": "Jersey Island",
    "jordan": "Jordan",
    "kazakhstan": "Kazakhstan",
    "kenya": "Kenya",
    "kiribati": "Kiribati",
    "korea, democratic peoples republic of": "Korea, Democratic Peoples Republic of",
    "korea, republic of": "Korea, Republic of",
    "kosovo": "Kosovo",
    "kuwait": "Kuwait",
    "kyrgyzstan": "Kyrgyzstan",
    "lao peoples democratic republic": "Lao Peoples Democratic Republic",
    "latvia": "Latvia",
    "lebanon": "Lebanon",
    "lesotho": "Lesotho",
    "liberia": "Liberia",
    "libyan arab jamahiriya": "Libyan Arab Jamahiriya",
    "liechtenstein": "Liechtenstein",
    "lithuania": "Lithuania",
    "luxembourg": "Luxembourg",
    "macau": "Macau",
    "macedonia, the former yugoslav republic of": "Macedonia, the former Yugoslav Republic of",
    "madagascar": "Madagascar",
    "malawi": "Malawi",
    "malaysia": "Malaysia",
    "maldives": "Maldives",
    "mali": "Mali",
    "malta": "Malta",
    "marshall islands": "Marshall Islands",
    "martinique": "Martinique",
    "mauritania": "Mauritania",
    "mauritius": "Mauritius",
    "mayotte": "Mayotte",
    "mexico": "Mexico",
    "micronesia, federated states of": "Micronesia, Federated States of",
    "moldova, republic of": "Moldova, republic of",
    "monaco": "Monaco",
    "mongolia": "Mongolia",
    "montenegro": "Montenegro",
    "montserrat": "Montserrat",
    "morocco": "Morocco",
    "mozambique": "Mozambique",
    "myanmar": "Myanmar",
    "namibia": "Namibia",
    "nauru": "Nauru",
    "nepal": "Nepal",
    "netherlands": "Netherlands",
    "netherlands antilles": "Netherlands Antilles",
    "new caledonia": "New Caledonia",
    "new zealand": "New Zealand",
    "nicaragua": "Nicaragua",
    "niger": "Niger",
    "nigeria": "Nigeria",
    "niue": "Niue",
    "norfolk island": "Norfolk Island",
    "northern mariana islands": "Northern Mariana Islands",
    "norway": "Norway",
    "oman": "Oman",
    "pakistan": "Pakistan",
    "palau": "Palau",
    "palestinian territory, occupied": "Palestinian Territory, Occupied",
    "panama": "Panama",
    "papua new guinea": "Papua New Guinea",
    "paraguay": "Paraguay",
    "peru": "Peru",
    "philippines": "Philippines",
    "pitcairn": "Pitcairn",
    "poland": "Poland",
    "portugal": "Portugal",
    "puerto rico": "Puerto Rico",
    "qatar": "Qatar",
    "reunion": "Reunion",
    "romania": "Romania",
    "russian federation": "Russian Federation",
    "rwanda": "Rwanda",
    "saint kitts and nevis": "Saint Kitts and Nevis",
    "saint lucia": "Saint Lucia",
    "saint vincent and the grenadines": "Saint Vincent and The Grenadines",
    "samoa": "Samoa",
    "san marino": "San Marino",
    "sao tome and principe": "Sao Tome and Principe",
    "saudi arabia": "Saudi Arabia",
    "senegal": "Senegal",
    "serbia": "Serbia",
    "seychelles": "Seychelles",
    "sierra leone": "Sierra Leone",
    "singapore": "Singapore",
    "slovakia (slovak republic)": "Slovakia (Slovak Republic)",
    "slovenia": "Slovenia",
    "solomon islands": "Solomon Islands",
    "somalia": "Somalia",
    "south africa": "South Africa",
    "south georgia and the south sandwich islands": "South Georgia and The South Sandwich Islands",
    "spain": "Spain",
    "sri lanka": "Sri Lanka",
    "st. helena": "St. Helena",
    "st. pierre and miquelon": "St. Pierre and Miquelon",
    "sudan": "Sudan",
    "suriname": "Suriname",
    "svalbard and jan mayen islands": "Svalbard and Jan Mayen Islands",
    "swaziland": "Swaziland",
    "sweden": "Sweden",
    "switzerland": "Switzerland",
    "syrian arab republic": "Syrian Arab Republic",
    "taiwan": "Taiwan",
    "tajikistan": "Tajikistan",
    "tanzania, united republic of": "Tanzania, United Republic of",
    "thailand": "Thailand",
    "the isle of man": "The Isle of Man",
    "togo": "Togo",
    "tokelau": "Tokelau",
    "tonga": "Tonga",
    "trinidad and tobago": "Trinidad and Tobago",
    "tunisia": "Tunisia",
    "turkey": "Turkey",
    "turkmenistan": "Turkmenistan",
    "turks and caicos islands": "Turks and Caicos Islands",
    "tuvalu": "Tuvalu",
    "uganda": "Uganda",
    "ukraine": "Ukraine",
    "united arab emirates": "United Arab Emirates",
    "united kingdom": "United Kingdom",
    "united states": "United States",
    "united states minor outlying islands": "United States Minor Outlying Islands",
    "uruguay": "Uruguay",
    "uzbekistan": "Uzbekistan",
    "vanuatu": "Vanuatu",
    "vatican city state (holy see)": "Vatican City State (Holy See)",
    "venezuela": "Venezuela",
    "vietnam": "Vietnam",
    "virgin islands (british)": "Virgin Islands (British)",
    "virgin islands (u.s.)": "Virgin Islands (U.S.)",
    "wallis and futuna islands": "Wallis and Futuna Islands",
    "western sahara": "Western Sahara",
    "yemen": "Yemen",
    "yugoslavia": "Yugoslavia",
    "zambia": "Zambia",
    "zimbabwe": "Zimbabwe",
    "select_no_results": "no results found",
    "residency_tooltip": "如您非列表中國家的納稅居民，請在下拉式功能表中選擇「非協議國家」。"
  };
});