define('w8ben-frontend/components/dropdown-component', ['exports', 'ember', 'w8ben-frontend/data/countryCodes'], function (exports, _ember, _w8benFrontendDataCountryCodes) {
  exports['default'] = _ember['default'].Component.extend({
    i18n: _ember['default'].inject.service(),
    reporting: _ember['default'].inject.service(),
    countryCodes: _w8benFrontendDataCountryCodes['default'],
    classNames: ['question__select'],
    didInsertElement: function didInsertElement() {
      var _this = this;

      var htmlElement = this.get('element').children[0];
      _ember['default'].run.schedule('afterRender', function () {
        var placeholder = _this.get('i18n').t('select_text').toString();

        var countryCode = _this.get('model.answer');
        if (countryCode) {
          for (var country in _w8benFrontendDataCountryCodes['default']) {
            if (_w8benFrontendDataCountryCodes['default'][country] === countryCode) {
              htmlElement.value = country;
              break;
            }
          }
        }

        _ember['default'].$(htmlElement).select2({
          placeholder: placeholder,
          language: {
            noResults: function noResults() {
              return _this.get('i18n').t('select_no_results').toString();
            }
          }
        });
      });
    },

    getCountryCode: function getCountryCode(countryName) {
      var countryCode = _w8benFrontendDataCountryCodes['default'][countryName];
      if (!countryCode) {
        this.get("reporting").error("Country code for " + countryName + " not found");
        return countryName;
      }
      return countryCode;
    },
    actions: {
      select: function select(item) {
        this.sendAction('answer', this.getCountryCode(item));
      }
    }
  });
});