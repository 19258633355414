define('w8ben-frontend/instance-initializers/error-tracking', ['exports', 'ember'], function (exports, _ember) {
  exports.initialize = initialize;

  function initAjaxErrorsHandler() {
    _ember['default'].$(document).ajaxError(function (event, jqXHR, ajaxSettings, thrownError) {
      Raven.captureMessage(thrownError || jqXHR.statusText, {
        extra: {
          type: ajaxSettings.type,
          url: ajaxSettings.url,
          data: ajaxSettings.data,
          status: jqXHR.status,
          error: thrownError || jqXHR.statusText,
          response: jqXHR.responseJSON
        }
      });
    });
  }

  function initialize(container) {
    var ENV = container.lookup('service:environment');
    if (container.application.FLAGS.reporting) {
      Raven.config(this.sentryURL, {
        release: '0e4fdef81448dcfa0e16ecc4433ff3997aa53572',
        environment: ENV.get('details').name,
        ignoreErrors: ['AlreadyHandledError'],
        version: container.application.buildVersion,
        tags: {
          version: container.application.buildVersion
        }
      }).addPlugin(Raven.Plugins.Ember).install();

      initAjaxErrorsHandler();
    }
  }

  exports['default'] = {
    name: 'error-tracking',
    sentryURL: "https://c7d39e25c0104045af0721bdd2f6f00d@sentry.io/109914",
    initialize: initialize
  };
});
/* global Raven */