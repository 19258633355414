define('w8ben-frontend/router', ['exports', 'ember', 'w8ben-frontend/config/environment'], function (exports, _ember, _w8benFrontendConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _w8benFrontendConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('lang', { path: '/:lang' }, function () {
      this.route('part1', { path: '/' });
      this.route('part2');
      this.route('certification');
      this.route('confirmation');
    });
    this.route('error');
  });

  exports['default'] = Router;
});