define("w8ben-frontend/templates/components/question-component", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 8
              },
              "end": {
                "line": 6,
                "column": 8
              }
            },
            "moduleName": "w8ben-frontend/templates/components/question-component.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "question__text_optional");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["question_elements_optional"], [], ["loc", [null, [5, 48], [5, 82]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 12,
                  "column": 14
                },
                "end": {
                  "line": 14,
                  "column": 14
                }
              },
              "moduleName": "w8ben-frontend/templates/components/question-component.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", [["get", "getTooltip", ["loc", [null, [13, 21], [13, 31]]], 0, 0, 0, 0]], [], ["loc", [null, [13, 16], [13, 35]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 8
              },
              "end": {
                "line": 16,
                "column": 8
              }
            },
            "moduleName": "w8ben-frontend/templates/components/question-component.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "question__more_info");
            dom.setAttribute(el1, "data-content", "i");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "tooltip-on-element", [], ["side", "top"], 0, null, ["loc", [null, [12, 14], [14, 37]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 8
              },
              "end": {
                "line": 20,
                "column": 8
              }
            },
            "moduleName": "w8ben-frontend/templates/components/question-component.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createUnsafeMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["content", "defaultValue", ["loc", [null, [19, 18], [19, 38]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 2
            },
            "end": {
              "line": 23,
              "column": 2
            }
          },
          "moduleName": "w8ben-frontend/templates/components/question-component.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "question__label");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "question__label__centered");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(element1, 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
          morphs[2] = dom.createMorphAt(element1, 5, 5);
          morphs[3] = dom.createMorphAt(element1, 7, 7);
          return morphs;
        },
        statements: [["block", "if", [["get", "model.isOptional", ["loc", [null, [4, 14], [4, 30]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [4, 8], [6, 15]]]], ["inline", "t", [["get", "getText", ["loc", [null, [8, 18], [8, 25]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 14], [8, 28]]], 0, 0], ["block", "if", [["get", "model.tooltip", ["loc", [null, [10, 14], [10, 27]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [10, 8], [16, 15]]]], ["block", "if", [["get", "defaultValue", ["loc", [null, [18, 14], [18, 26]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [18, 8], [20, 16]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 4
            },
            "end": {
              "line": 30,
              "column": 4
            }
          },
          "moduleName": "w8ben-frontend/templates/components/question-component.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "yesno-input-component", [], ["name", ["subexpr", "@mut", [["get", "name", ["loc", [null, [27, 15], [27, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [28, 16], [28, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "answer", ["subexpr", "action", ["answer"], [], ["loc", [null, [29, 17], [29, 34]]], 0, 0]], ["loc", [null, [26, 6], [29, 37]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 4
            },
            "end": {
              "line": 38,
              "column": 4
            }
          },
          "moduleName": "w8ben-frontend/templates/components/question-component.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "dropdown-component", [], ["name", ["subexpr", "@mut", [["get", "name", ["loc", [null, [34, 15], [34, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [35, 16], [35, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "list", ["subexpr", "@mut", [["get", "list", ["loc", [null, [36, 15], [36, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "answer", ["subexpr", "action", ["answer"], [], ["loc", [null, [37, 17], [37, 34]]], 0, 0]], ["loc", [null, [33, 6], [37, 37]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 40,
              "column": 4
            },
            "end": {
              "line": 46,
              "column": 4
            }
          },
          "moduleName": "w8ben-frontend/templates/components/question-component.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "date-component", [], ["name", ["subexpr", "@mut", [["get", "name", ["loc", [null, [42, 15], [42, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "date", ["subexpr", "@mut", [["get", "date", ["loc", [null, [43, 15], [43, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [44, 16], [44, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "answer", ["subexpr", "action", ["answer"], [], ["loc", [null, [45, 17], [45, 34]]], 0, 0]], ["loc", [null, [41, 6], [45, 37]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 48,
              "column": 4
            },
            "end": {
              "line": 54,
              "column": 4
            }
          },
          "moduleName": "w8ben-frontend/templates/components/question-component.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "date-input-component", [], ["name", ["subexpr", "@mut", [["get", "name", ["loc", [null, [50, 15], [50, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "date", ["subexpr", "@mut", [["get", "date", ["loc", [null, [51, 15], [51, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [52, 16], [52, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "answer", ["subexpr", "action", ["answer"], [], ["loc", [null, [53, 17], [53, 34]]], 0, 0]], ["loc", [null, [49, 6], [53, 37]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 56,
              "column": 4
            },
            "end": {
              "line": 58,
              "column": 4
            }
          },
          "moduleName": "w8ben-frontend/templates/components/question-component.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "input", [], ["key-up", "answer", "value", ["subexpr", "@mut", [["get", "model.answer", ["loc", [null, [57, 37], [57, 49]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [57, 6], [57, 52]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child6 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 60,
              "column": 4
            },
            "end": {
              "line": 65,
              "column": 4
            }
          },
          "moduleName": "w8ben-frontend/templates/components/question-component.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "checkbox-component", [], ["name", ["subexpr", "@mut", [["get", "name", ["loc", [null, [62, 15], [62, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [63, 16], [63, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "answer", ["subexpr", "action", ["answer"], [], ["loc", [null, [64, 17], [64, 34]]], 0, 0]], ["loc", [null, [61, 6], [64, 37]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child7 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 67,
              "column": 4
            },
            "end": {
              "line": 72,
              "column": 4
            }
          },
          "moduleName": "w8ben-frontend/templates/components/question-component.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "yesno-component", [], ["name", ["subexpr", "@mut", [["get", "name", ["loc", [null, [69, 15], [69, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [70, 16], [70, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "answer", ["subexpr", "action", ["answer"], [], ["loc", [null, [71, 17], [71, 34]]], 0, 0]], ["loc", [null, [68, 6], [71, 37]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child8 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 79,
                "column": 6
              },
              "end": {
                "line": 81,
                "column": 6
              }
            },
            "moduleName": "w8ben-frontend/templates/components/question-component.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createUnsafeMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", [["get", "getErrorMessageNextLine", ["loc", [null, [80, 13], [80, 36]]], 0, 0, 0, 0]], [], ["loc", [null, [80, 8], [80, 40]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 75,
              "column": 2
            },
            "end": {
              "line": 83,
              "column": 2
            }
          },
          "moduleName": "w8ben-frontend/templates/components/question-component.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "question__message");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createUnsafeMorphAt(element0, 1, 1);
          morphs[1] = dom.createMorphAt(element0, 5, 5);
          return morphs;
        },
        statements: [["inline", "t", [["get", "getErrorMessage", ["loc", [null, [77, 11], [77, 26]]], 0, 0, 0, 0]], [], ["loc", [null, [77, 6], [77, 30]]], 0, 0], ["block", "if", [["get", "model.errorMessageNextLine", ["loc", [null, [79, 12], [79, 38]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [79, 6], [81, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child9 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 86,
                "column": 4
              },
              "end": {
                "line": 90,
                "column": 4
              }
            },
            "moduleName": "w8ben-frontend/templates/components/question-component.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "question__message");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "t", [["get", "getDynamicErrorMessage", ["loc", [null, [88, 13], [88, 35]]], 0, 0, 0, 0]], [], ["loc", [null, [88, 8], [88, 39]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 85,
              "column": 2
            },
            "end": {
              "line": 91,
              "column": 2
            }
          },
          "moduleName": "w8ben-frontend/templates/components/question-component.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "dynamicError", ["loc", [null, [86, 10], [86, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [86, 4], [90, 11]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 92,
            "column": 0
          }
        },
        "moduleName": "w8ben-frontend/templates/components/question-component.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "question__inner");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [2]);
        var morphs = new Array(10);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(element2, 1, 1);
        morphs[2] = dom.createMorphAt(element2, 3, 3);
        morphs[3] = dom.createMorphAt(element2, 5, 5);
        morphs[4] = dom.createMorphAt(element2, 7, 7);
        morphs[5] = dom.createMorphAt(element2, 9, 9);
        morphs[6] = dom.createMorphAt(element2, 11, 11);
        morphs[7] = dom.createMorphAt(element2, 13, 13);
        morphs[8] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[9] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "model.text", ["loc", [null, [1, 8], [1, 18]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 2], [23, 9]]]], ["block", "if", [["subexpr", "eq", [["get", "model.type", ["loc", [null, [25, 14], [25, 24]]], 0, 0, 0, 0], "yesnoinput"], [], ["loc", [null, [25, 10], [25, 38]]], 0, 0]], [], 1, null, ["loc", [null, [25, 4], [30, 11]]]], ["block", "if", [["subexpr", "eq", [["get", "model.type", ["loc", [null, [32, 14], [32, 24]]], 0, 0, 0, 0], "select"], [], ["loc", [null, [32, 10], [32, 34]]], 0, 0]], [], 2, null, ["loc", [null, [32, 4], [38, 11]]]], ["block", "if", [["subexpr", "eq", [["get", "model.type", ["loc", [null, [40, 14], [40, 24]]], 0, 0, 0, 0], "date"], [], ["loc", [null, [40, 10], [40, 32]]], 0, 0]], [], 3, null, ["loc", [null, [40, 4], [46, 11]]]], ["block", "if", [["subexpr", "eq", [["get", "model.type", ["loc", [null, [48, 14], [48, 24]]], 0, 0, 0, 0], "date-input"], [], ["loc", [null, [48, 10], [48, 38]]], 0, 0]], [], 4, null, ["loc", [null, [48, 4], [54, 11]]]], ["block", "if", [["subexpr", "eq", [["get", "model.type", ["loc", [null, [56, 14], [56, 24]]], 0, 0, 0, 0], "input"], [], ["loc", [null, [56, 10], [56, 33]]], 0, 0]], [], 5, null, ["loc", [null, [56, 4], [58, 11]]]], ["block", "if", [["subexpr", "eq", [["get", "model.type", ["loc", [null, [60, 14], [60, 24]]], 0, 0, 0, 0], "checkbox"], [], ["loc", [null, [60, 10], [60, 36]]], 0, 0]], [], 6, null, ["loc", [null, [60, 4], [65, 11]]]], ["block", "unless", [["get", "model.type", ["loc", [null, [67, 14], [67, 24]]], 0, 0, 0, 0]], [], 7, null, ["loc", [null, [67, 4], [72, 15]]]], ["block", "if", [["get", "model.hasWarningMessage", ["loc", [null, [75, 8], [75, 31]]], 0, 0, 0, 0]], [], 8, null, ["loc", [null, [75, 2], [83, 9]]]], ["block", "unless", [["get", "model.hasWarningMessage", ["loc", [null, [85, 12], [85, 35]]], 0, 0, 0, 0]], [], 9, null, ["loc", [null, [85, 2], [91, 13]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9]
    };
  })());
});