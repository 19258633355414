define('w8ben-frontend/components/date-input-component', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: ['focusField_day:day_focused', 'focusField_month:month_focused', 'focusField_year:year_focused'],
    fullDate: _ember['default'].computed('day', 'month', 'year', function () {
      var day = this.get('day');
      var month = this.get('month');
      var year = this.get('year');
      if (day && day <= 31 && day >= 1 && month && month <= 12 && month >= 1 && year && year >= 1900 && year <= 2100) {
        var dob = (0, _moment['default'])().set({
          year: this.get('year'),
          month: this.get('month') - 1,
          date: this.get('day')
        });
        var now = (0, _moment['default'])().utc();
        if (now.diff(dob, 'years') >= 18) {
          return dob;
        }
      }
      return null;
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var answer = this.get('model.answer');
      if (answer && _moment['default'].isMoment(answer)) {
        this.set('year', answer.get('year'));
        this.set('month', answer.get('month') + 1);
        this.set('day', answer.get('date'));
      }
    },
    actions: {
      answer: function answer() {
        this.sendAction('answer', this.get('fullDate'));
      }
    }
  });
});