define('w8ben-frontend/services/user', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    reporting: _ember['default'].inject.service(),
    environment: _ember['default'].inject.service(),
    pageError: _ember['default'].inject.service(),
    data: null,
    url: '/getclientdetailsforw8benform',
    getUrl: function getUrl() {
      return this.get('environment.details.apiSource') + this.url;
    },
    getData: function getData() {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        if (!_this.get('data')) {
          return _ember['default'].$.getJSON(_this.getUrl()).then(function (data) {
            if (data.personalAccount === undefined || data.personalAccount === null) {
              _this.get("reporting").error("userdata endpoint returned data without personalAccount");
              _this.get("pageError").show();
            }
            _this.set('data', data);
            resolve(_this.get('data'));
          }).fail(function (reason, status) {
            reject(reason.responseText || status);
          });
        } else {
          resolve(_this.get('data'));
        }
      });
    }
  });
});