define('w8ben-frontend/controllers/lang/part1', ['exports', 'ember', 'w8ben-frontend/mixins/page'], function (exports, _ember, _w8benFrontendMixinsPage) {
  exports['default'] = _ember['default'].Controller.extend(_w8benFrontendMixinsPage['default'], {
    corporateError: _ember['default'].computed('questionsData.individual_acting.answer', 'userData.personalAccount', function () {
      return this.get('userData.personalAccount') !== true;
    }),

    showUsBorne: _ember['default'].computed('questionsData.individual_acting.answer', 'userData.personalAccount', function () {
      return this.get('questionsData.individual_acting.answer') === true && this.get('userData.personalAccount') === true;
    }),

    showFormerRenounceCitizenship: _ember['default'].computed.equal('questionsData.us_born.answer', true),
    showPresentTaxationSubject: _ember['default'].computed('questionsData.us_born.answer', 'questionsData.former_renounce_citizenship.answer', function () {
      return this.get('questionsData.us_born.answer') === false || this.get('questionsData.former_renounce_citizenship.answer') === true;
    }),
    showConductIncome: _ember['default'].computed.equal('questionsData.present_taxation_subject.answer', false),
    showIntermediaryActing: _ember['default'].computed.equal('questionsData.conduct_income.answer', false),
    showIntermediaryActingMessage: _ember['default'].computed.equal('questionsData.intermediary_acting.answer', false),
    showIrsGuidance: _ember['default'].computed.equal('questionsData.intermediary_acting.answer', true),
    showSubmit: _ember['default'].computed.equal('questionsData.intermediary_acting.answer', false),

    actions: {
      submit: function submit() {
        this.transitionToRoute('lang.part2');
      }
    }
  });
});