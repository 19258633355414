define('w8ben-frontend/services/page-error', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend({
        routing: _ember['default'].inject.service('-routing'),
        show: function show() {
            var _this = this;

            _ember['default'].run.schedule('afterRender', function () {
                _this.get("routing").transitionTo("error");
            });
        }
    });
});