define('w8ben-frontend/data/questions', ['exports'], function (exports) {
  exports['default'] = {
    individual_acting: {
      text: 'individual_acting_text',
      errorMessageCondition: false,
      errorMessage: 'form_for_individuals_only',
      errorMessageNextLine: 'add_us_stocks_text',
      save: true
    },
    us_born: {
      text: 'us_born_text'
    },
    former_renounce_citizenship: {
      text: 'former_renounce_citizenship_text',
      errorMessageCondition: false,
      errorMessage: 'former_renounce_citizenship_error_message'
    },
    present_taxation_subject: {
      text: 'present_taxation_subject_text',
      errorMessageCondition: true,
      errorMessage: 'present_taxation_subject_error_message',
      answer: ''
    },
    conduct_income: {
      text: 'conduct_income_text',
      tooltip: 'conduct_income_tooltip',
      errorMessageCondition: true,
      errorMessage: 'conduct_income_error_message'
    },
    intermediary_acting: {
      text: 'intermediary_acting_text',
      tooltip: 'intermediary_acting_tooltip'
    },
    name_confirm: {
      text: 'name_confirm_text',
      errorMessage: 'page_to_personal_details',
      errorMessage_mobile: 'page_to_personal_details_mobile',
      errorMessageCondition: false,
      validators: [{
        'validatePresence': 'unanswered_error'
      }]
    },
    citizenship: {
      type: 'select',
      text: 'citizenship_text',
      errorMessage: 'citizenship_us_selected',
      errorMessageCondition: "US",
      tooltip: 'citizenship_tooltip',
      validators: [{
        'validatePresence': 'unanswered_error'
      }]
    },
    address_confirm: {
      text: 'address_confirm_text',
      errorMessage: 'page_to_personal_details',
      errorMessage_mobile: 'page_to_personal_details_mobile',
      errorMessageCondition: false,
      tooltip: 'address_confirm_tooltip',
      validators: [{
        'validatePresence': 'unanswered_error'
      }]
    },
    country: {
      type: 'select',
      text: 'country_text',
      errorMessage: 'citizenship_us_selected',
      dynamicErrorMessage: 'page_to_personal_details',
      dynamicErrorMessage_mobile: 'page_to_personal_details_mobile',
      errorMessageCondition: "US",
      validators: [{
        'validatePresence': 'unanswered_error'
      }]
    },
    mailing_address_same: {
      text: 'mailing_address_same_text',
      errorMessage: 'page_to_personal_details',
      errorMessage_mobile: 'page_to_personal_details_mobile',
      errorMessageCondition: false,
      validators: [{
        'validatePresence': 'unanswered_error'
      }]
    },
    have_ssn_confirm: {
      text: 'have_ssn_confirm_text',
      type: 'yesnoinput',
      tooltip: 'have_ssn_confirm_tooltip',
      validators: [{
        'validatePresence': 'unanswered_error'
      }],
      input_placeholder: 'have_ssn_confirm_input_placeholder',
      inputCondition: true
    },
    tin_number: {
      text: 'tin_number_text',
      isOptional: true,
      type: 'input'
    },
    username_confirm: {
      text: 'username_confirm_text',
      errorMessage: 'username_confirm_validation_error',
      errorMessage_mobile: 'username_confirm_validation_error',
      errorMessageCondition: false,
      validators: [{
        'validatePresence': 'unanswered_error'
      }]
    },
    dob_confirm: {
      text: 'dob_text',
      type: 'date',
      validators: [{
        'validatePresence': 'unanswered_error'
      }]
    },
    dob_insert: {
      text: 'dob_insert_text',
      type: 'date-input',
      validators: [{
        'dobInsertValidator': 'unanswered_error'
      }]
    },
    residency: {
      text: 'residency_text',
      type: 'select',
      tooltip: 'residency_tooltip',
      validators: [{
        'validatePresence': 'unanswered_error',
        'residencyValidator': {
          desktop: 'residency_validation_error',
          mobile: 'residency_validation_error_mobile'
        }
      }]
    },
    accept_esign: {
      type: 'checkbox',
      checkBoxLabel: 'accept_esign_label',
      validators: [{
        'validatePresence': 'unanswered_error'
      }]
    }
  };
});