define("w8ben-frontend/templates/components/yesno-component", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 64
          }
        },
        "moduleName": "w8ben-frontend/templates/components/yesno-component.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("input");
        dom.setAttribute(el1, "class", "question__radio");
        dom.setAttribute(el1, "type", "radio");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("label");
        dom.setAttribute(el1, "class", "question__radio_label question__radio_label--first");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("input");
        dom.setAttribute(el1, "class", "question__radio");
        dom.setAttribute(el1, "type", "radio");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("label");
        dom.setAttribute(el1, "class", "question__radio_label question__radio_label--last");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        if (this.cachedFragment) {
          dom.repairClonedNode(element0, [], true);
        }
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(fragment, [4]);
        if (this.cachedFragment) {
          dom.repairClonedNode(element2, [], true);
        }
        var element3 = dom.childAt(fragment, [6]);
        var morphs = new Array(13);
        morphs[0] = dom.createAttrMorph(element0, 'id');
        morphs[1] = dom.createAttrMorph(element0, 'name');
        morphs[2] = dom.createAttrMorph(element0, 'checked');
        morphs[3] = dom.createElementMorph(element0);
        morphs[4] = dom.createAttrMorph(element1, 'for');
        morphs[5] = dom.createMorphAt(element1, 1, 1);
        morphs[6] = dom.createAttrMorph(element2, 'id');
        morphs[7] = dom.createAttrMorph(element2, 'name');
        morphs[8] = dom.createAttrMorph(element2, 'checked');
        morphs[9] = dom.createElementMorph(element2);
        morphs[10] = dom.createAttrMorph(element3, 'for');
        morphs[11] = dom.createMorphAt(element3, 1, 1);
        morphs[12] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["attribute", "id", ["concat", ["yes_", ["get", "name", ["loc", [null, [1, 55], [1, 59]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "name", ["concat", [["get", "name", ["loc", [null, [1, 73], [1, 77]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "checked", ["concat", [["subexpr", "if", [["get", "model.answer", ["loc", [null, [1, 96], [1, 108]]], 0, 0, 0, 0], "checked"], [], ["loc", [null, [1, 91], [1, 121]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["check", true], ["on", "change"], ["loc", [null, [1, 123], [1, 159]]], 0, 0], ["attribute", "for", ["concat", ["yes_", ["get", "name", ["loc", [null, [2, 78], [2, 82]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["buttons_yes"], [], ["loc", [null, [3, 2], [3, 22]]], 0, 0], ["attribute", "id", ["concat", ["no_", ["get", "name", ["loc", [null, [6, 54], [6, 58]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "name", ["get", "name", ["loc", [null, [6, 71], [6, 75]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "checked", ["concat", [["subexpr", "if", [["subexpr", "eq", [["get", "model.answer", ["loc", [null, [6, 97], [6, 109]]], 0, 0, 0, 0], false], [], ["loc", [null, [6, 93], [6, 116]]], 0, 0], "checked"], [], ["loc", [null, [6, 88], [6, 129]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["check", false], ["on", "change"], ["loc", [null, [6, 131], [6, 168]]], 0, 0], ["attribute", "for", ["concat", ["no_", ["get", "name", ["loc", [null, [7, 76], [7, 80]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["buttons_no"], [], ["loc", [null, [8, 2], [8, 21]]], 0, 0], ["inline", "errors-list-component", [], ["errors", ["subexpr", "@mut", [["get", "model.validationErrorsMessages", ["loc", [null, [11, 31], [11, 61]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [11, 0], [11, 64]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});