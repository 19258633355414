define("w8ben-frontend/templates/lang/part1", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 5,
              "column": 0
            }
          },
          "moduleName": "w8ben-frontend/templates/lang/part1.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1, "class", "lid");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["page_page1_lid"], [], ["loc", [null, [4, 17], [4, 39]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 2
              },
              "end": {
                "line": 14,
                "column": 2
              }
            },
            "moduleName": "w8ben-frontend/templates/lang/part1.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "question-component", [], ["name", "individual_acting", "model", ["subexpr", "@mut", [["get", "questionsData.individual_acting", ["loc", [null, [11, 13], [11, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "removeElement", ["subexpr", "action", ["removeElement"], [], ["loc", [null, [12, 20], [12, 44]]], 0, 0], "answer", ["subexpr", "action", ["answer"], [], ["loc", [null, [13, 13], [13, 30]]], 0, 0]], ["loc", [null, [9, 4], [13, 33]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 2
              },
              "end": {
                "line": 19,
                "column": 2
              }
            },
            "moduleName": "w8ben-frontend/templates/lang/part1.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["form_for_individuals_only"], [], ["loc", [null, [17, 4], [17, 38]]], 0, 0], ["inline", "t", ["add_us_stocks_text"], [], ["loc", [null, [18, 4], [18, 31]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 2
              },
              "end": {
                "line": 27,
                "column": 2
              }
            },
            "moduleName": "w8ben-frontend/templates/lang/part1.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "question-component", [], ["name", "us_born", "model", ["subexpr", "@mut", [["get", "questionsData.us_born", ["loc", [null, [24, 13], [24, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "removeElement", ["subexpr", "action", ["removeElement"], [], ["loc", [null, [25, 20], [25, 44]]], 0, 0], "answer", ["subexpr", "action", ["answer"], [], ["loc", [null, [26, 13], [26, 30]]], 0, 0]], ["loc", [null, [22, 4], [26, 34]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 2
              },
              "end": {
                "line": 35,
                "column": 2
              }
            },
            "moduleName": "w8ben-frontend/templates/lang/part1.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "question-component", [], ["name", "former_renounce_citizenship", "model", ["subexpr", "@mut", [["get", "questionsData.former_renounce_citizenship", ["loc", [null, [32, 13], [32, 54]]], 0, 0, 0, 0]], [], [], 0, 0], "removeElement", ["subexpr", "action", ["removeElement"], [], ["loc", [null, [33, 20], [33, 44]]], 0, 0], "answer", ["subexpr", "action", ["answer"], [], ["loc", [null, [34, 13], [34, 30]]], 0, 0]], ["loc", [null, [30, 4], [34, 34]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 37,
                "column": 2
              },
              "end": {
                "line": 43,
                "column": 2
              }
            },
            "moduleName": "w8ben-frontend/templates/lang/part1.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "question-component", [], ["name", "present_taxation_subject", "model", ["subexpr", "@mut", [["get", "questionsData.present_taxation_subject", ["loc", [null, [40, 13], [40, 51]]], 0, 0, 0, 0]], [], [], 0, 0], "removeElement", ["subexpr", "action", ["removeElement"], [], ["loc", [null, [41, 20], [41, 44]]], 0, 0], "answer", ["subexpr", "action", ["answer"], [], ["loc", [null, [42, 13], [42, 30]]], 0, 0]], ["loc", [null, [38, 4], [42, 34]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child5 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 45,
                "column": 2
              },
              "end": {
                "line": 51,
                "column": 2
              }
            },
            "moduleName": "w8ben-frontend/templates/lang/part1.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "question-component", [], ["name", "conduct_income", "model", ["subexpr", "@mut", [["get", "questionsData.conduct_income", ["loc", [null, [48, 13], [48, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "removeElement", ["subexpr", "action", ["removeElement"], [], ["loc", [null, [49, 20], [49, 44]]], 0, 0], "answer", ["subexpr", "action", ["answer"], [], ["loc", [null, [50, 13], [50, 30]]], 0, 0]], ["loc", [null, [46, 4], [50, 35]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child6 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 53,
                "column": 2
              },
              "end": {
                "line": 59,
                "column": 2
              }
            },
            "moduleName": "w8ben-frontend/templates/lang/part1.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "question-component", [], ["name", "intermediary_acting", "model", ["subexpr", "@mut", [["get", "questionsData.intermediary_acting", ["loc", [null, [56, 13], [56, 46]]], 0, 0, 0, 0]], [], [], 0, 0], "removeElement", ["subexpr", "action", ["removeElement"], [], ["loc", [null, [57, 20], [57, 44]]], 0, 0], "answer", ["subexpr", "action", ["answer"], [], ["loc", [null, [58, 13], [58, 30]]], 0, 0]], ["loc", [null, [54, 4], [58, 35]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child7 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 61,
                "column": 2
              },
              "end": {
                "line": 63,
                "column": 2
              }
            },
            "moduleName": "w8ben-frontend/templates/lang/part1.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["intermediary_acting_error_message"], [], ["loc", [null, [62, 9], [62, 51]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child8 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 65,
                "column": 2
              },
              "end": {
                "line": 67,
                "column": 2
              }
            },
            "moduleName": "w8ben-frontend/templates/lang/part1.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["page_irs_guidance"], [], ["loc", [null, [66, 9], [66, 35]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child9 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 69,
                "column": 2
              },
              "end": {
                "line": 71,
                "column": 2
              }
            },
            "moduleName": "w8ben-frontend/templates/lang/part1.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "type", "submit");
            dom.setAttribute(el1, "class", "submit_form");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createMorphAt(element0, 0, 0);
            return morphs;
          },
          statements: [["element", "action", ["submit"], [], ["loc", [null, [70, 48], [70, 69]]], 0, 0], ["inline", "t", ["submit_index"], [], ["loc", [null, [70, 70], [70, 90]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 0
            },
            "end": {
              "line": 72,
              "column": 0
            }
          },
          "moduleName": "w8ben-frontend/templates/lang/part1.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(10);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
          morphs[4] = dom.createMorphAt(fragment, 8, 8, contextualElement);
          morphs[5] = dom.createMorphAt(fragment, 10, 10, contextualElement);
          morphs[6] = dom.createMorphAt(fragment, 12, 12, contextualElement);
          morphs[7] = dom.createMorphAt(fragment, 14, 14, contextualElement);
          morphs[8] = dom.createMorphAt(fragment, 16, 16, contextualElement);
          morphs[9] = dom.createMorphAt(fragment, 18, 18, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "unless", [["get", "corporateError", ["loc", [null, [8, 12], [8, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [8, 2], [14, 13]]]], ["block", "if", [["get", "corporateError", ["loc", [null, [16, 8], [16, 22]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [16, 2], [19, 9]]]], ["block", "if", [["get", "showUsBorne", ["loc", [null, [21, 8], [21, 19]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [21, 2], [27, 9]]]], ["block", "if", [["get", "showFormerRenounceCitizenship", ["loc", [null, [29, 8], [29, 37]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [29, 2], [35, 9]]]], ["block", "if", [["get", "showPresentTaxationSubject", ["loc", [null, [37, 8], [37, 34]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [37, 2], [43, 9]]]], ["block", "if", [["get", "showConductIncome", ["loc", [null, [45, 8], [45, 25]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [45, 2], [51, 9]]]], ["block", "if", [["get", "showIntermediaryActing", ["loc", [null, [53, 8], [53, 30]]], 0, 0, 0, 0]], [], 6, null, ["loc", [null, [53, 2], [59, 9]]]], ["block", "if", [["get", "showIntermediaryActingMessage", ["loc", [null, [61, 8], [61, 37]]], 0, 0, 0, 0]], [], 7, null, ["loc", [null, [61, 2], [63, 9]]]], ["block", "if", [["get", "showIrsGuidance", ["loc", [null, [65, 8], [65, 23]]], 0, 0, 0, 0]], [], 8, null, ["loc", [null, [65, 2], [67, 9]]]], ["block", "if", [["get", "showSubmit", ["loc", [null, [69, 8], [69, 18]]], 0, 0, 0, 0]], [], 9, null, ["loc", [null, [69, 2], [71, 9]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 72,
            "column": 19
          }
        },
        "moduleName": "w8ben-frontend/templates/lang/part1.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h1");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "t", ["page_title"], [], ["loc", [null, [1, 4], [1, 22]]], 0, 0], ["block", "unless", [["get", "corporateError", ["loc", [null, [3, 10], [3, 24]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 0], [5, 11]]]], ["block", "form-component", [["subexpr", "action", ["submit"], ["on", "submit"], ["loc", [null, [7, 18], [7, 47]]], 0, 0]], [], 1, null, ["loc", [null, [7, 0], [72, 19]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});