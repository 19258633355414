define('w8ben-frontend/mixins/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    questions: _ember['default'].inject.service(),
    reporting: _ember['default'].inject.service(),
    pageError: _ember['default'].inject.service(),
    tracking: _ember['default'].inject.service(),
    userData: _ember['default'].inject.service('user'),
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('userData', model);
      controller.set('questions', this.get('questions'));
    },
    model: function model() {
      var _this = this;

      return this.get('userData').getData().then(function (data) {
        return data;
      }, function (reason) {
        _this.get("reporting").error("getting userdata call failed with a reason: " + reason);
        _this.get("pageError").show();
      });
    },
    scrollToTop: function scrollToTop() {
      _ember['default'].$('body').scrollTop(0);
    },
    actions: {
      scrollToError: function scrollToError() {
        var errors = this.$('.error');
        _ember['default'].$(window).scrollTop(errors.offset().top);
        return errors.length;
      },
      willTransition: function willTransition(transition) {
        this.controllerFor('application').set('currentStep', transition.targetName);
        this.get('questions').saveAnswers();
      },
      didTransition: function didTransition() {
        var _this2 = this;

        var availableRoutes = ['index', this.routeName];
        this.get('questions').loadSavedAnswers();
        if (!availableRoutes.includes(this.controllerFor('application').get('currentStep'))) {
          this.replaceWith('lang.part1');
        }
        this.scrollToTop();

        _ember['default'].run.schedule("afterRender", function () {
          _this2.get('tracking').simulatePageview(_this2.router.get('url'));
          if (window.parent) {
            window.parent.postMessage({
              eventName: 'w8ben/height/set',
              height: document.body.clientHeight + 20
            }, '*');
          }
        });
      }
    }
  });
});