define('w8ben-frontend/utils/custom', ['exports', 'ember'], function (exports, _ember) {
  exports.extractAnswers = extractAnswers;
  exports.bulkUpdateAnswers = bulkUpdateAnswers;
  exports.serialize = serialize;

  function extractAnswers(list, actualAnswers) {
    var answers = actualAnswers || {};
    for (var i in list) {
      if (list.hasOwnProperty(i) && _ember['default'].typeOf(list[i].answer) !== 'null' && _ember['default'].typeOf(list[i].answer) !== 'undefined') {
        _ember['default'].set(answers, i, list[i].answer);
      }
    }

    return answers;
  }

  function bulkUpdateAnswers(questionList, answers) {
    for (var i in answers) {
      questionList.get(i).set('answer', answers[i]);
    }
  }

  function serialize(object) {
    var result = {};
    for (var key in object) {
      if (object.hasOwnProperty(key)) {
        result[key] = object[key];
      }
    }
    return result;
  }
});