define('w8ben-frontend/controllers/lang/confirmation', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Controller.extend({
    moment: _moment['default'],
    validUntil: _ember['default'].computed(function () {
      return this.moment().endOf('year').add(3, 'y').format("DD/MM/YYYY");
    }),
    updateDate: _ember['default'].computed(function () {
      return this.moment().add(3, 'y').format("YYYY");
    })
  });
});