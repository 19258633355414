define("w8ben-frontend/initializers/nativeinterop", ["exports", "ember"], function (exports, _ember) {
  exports.initialize = initialize;

  function initialize(application) {
    if (window.location.hostname !== "localhost") {
      document.domain = "ig.com";
    }

    if (application.FLAGS.nativeInterOp) {
      (function () {
        application.deferReadiness();
        var eventTimeOut = setTimeout(function () {
          Raven.captureMessage('cst token not received');
        }, 10000);

        window.addEventListener("message", function (event) {
          if (event.data.eventName === "w8ben/cst/set") {
            if (!event.data.cst) {
              Raven.captureMessage("Received event without cst token", {
                level: 'error'
              });
            }
            _ember["default"].$.ajaxSetup({
              beforeSend: function beforeSend(xhr) {
                xhr.setRequestHeader('cst', event.data.cst);
              }
            });
            clearTimeout(eventTimeOut);
            application.advanceReadiness();
          }
        }, false);

        if (window.parent) {
          window.parent.postMessage("w8ben/ready", "*");
        }
      })();
    }
  }

  exports["default"] = {
    name: 'nativeinterop',
    initialize: initialize
  };
});