define('w8ben-frontend/services/device', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    deviceName: null,
    isMobile: _ember['default'].computed.equal('deviceName', 'mobile'),
    isDesktop: _ember['default'].computed.equal('deviceName', 'desktop'),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('deviceName', this.get('container').lookup('controller:application').get('device'));
    }
  });
});