define('w8ben-frontend/validators/custom', ['exports', 'ember', 'moment', 'w8ben-frontend/data/tax_treaty_countries', 'w8ben-frontend/data/countryCodes'], function (exports, _ember, _moment, _w8benFrontendDataTax_treaty_countries, _w8benFrontendDataCountryCodes) {

  function validatePresence(question) {
    var answerType = _ember['default'].typeOf(question.get('answer'));
    return answerType !== 'null' && answerType !== 'undefined';
  }

  function dobInsertValidator(question) {
    var date = question.get('answer'),
        now = (0, _moment['default'])();
    return _moment['default'].isMoment(date) && date.isValid() && 18 <= now.diff(date, 'years');
  }

  function residencyValidator(question, items) {
    var countryAnswer = items.get('country.answer');

    if (validatePresence(question)) {
      if (question.get('answer') !== 'NT') {
        return question.get('answer') === countryAnswer;
      } else {
        for (var i = 0; i < _w8benFrontendDataTax_treaty_countries['default'].length; i++) {
          if (_w8benFrontendDataCountryCodes['default'][_w8benFrontendDataTax_treaty_countries['default'][i]] === countryAnswer) {
            return false;
          }
        }
        return true;
      }
    }

    return false;
  }

  exports['default'] = {
    validatePresence: validatePresence,
    dobInsertValidator: dobInsertValidator,
    residencyValidator: residencyValidator
  };
});