define('w8ben-frontend/mixins/page', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Mixin.create({
    device: _ember['default'].inject.service(),
    questions: _ember['default'].inject.service(),
    validator: _ember['default'].inject.service(),
    questionsData: _ember['default'].computed.alias('questions.items'),
    checkFilledAnswers: function checkFilledAnswers() {
      var _this = this;

      _ember['default'].run.next(this, function () {
        var unAnsweredItems = _this.get('requiredQuestions').reduce(function (count, requiredQuestion) {
          var question = _this.get('questions.items.' + requiredQuestion),
              answer = get(question, 'answer');
          if (_ember['default'].isNone(answer) || get(question, 'hasWarningMessage')) {
            return ++count;
          }
          return count;
        }, 0);

        _this.set('allAnswersFilled', unAnsweredItems === 0);
      });
    },

    scrollToError: function scrollToError() {
      _ember['default'].run.later(function () {
        var $firstError = _ember['default'].$('.ember-view.error');
        if ($firstError.length > 0) {
          _ember['default'].$(window).scrollTop($firstError.first().offset().top);
        }
      });
    },

    actions: {
      saveAnswers: function saveAnswers() {
        this.set('savedAnswers');
      },

      answer: function answer(questionId, _answer) {
        this.set('questionsData.' + questionId + '.answer', _answer);
        if (this.get('requiredQuestions')) {
          this.checkFilledAnswers();
        }
        _ember['default'].run.schedule('afterRender', this, function () {
          if (window.parent) {
            window.parent.postMessage({
              eventName: 'w8ben/height/set',
              height: document.body.clientHeight + 20
            }, '*');
          }
        });
      },

      removeElement: function removeElement(questionId) {
        this.set('questionsData.' + questionId + '.answer', null);
      },

      submit: function submit() {
        var validator = this.get('validator');

        validator.validate(this.get('questions').getQuestionsById(this.get('requiredQuestions')));
        if (get(validator, 'isValid')) {
          this.send('onSubmit');
        } else {
          this.scrollToError();
        }
      }
    }
  });
});